import React from 'react'

export const UserContext = React.createContext(null)
export const ModalsContext = React.createContext({})
export const OnUserContext = React.createContext(null)
export const RemoteConfigContext = React.createContext(null)

export const withUser = Component => props => (
  <UserContext.Consumer>
    {user => (
      <Component {...props} user={user} admin={!!(user && user.level > 0)} />
    )}
  </UserContext.Consumer>
)

/**
 * Created for circumstances where 'user' is passed in as a prop, so withUser would cause conflicting prop names
 * @param Component
 * @returns {function(*): *}
 */
export const withViewer = Component => props => (
  <UserContext.Consumer>
    {user => (
      <Component
        {...props}
        viewer={user}
        admin={!!(user && user.level > 0)}
        premium={user && (user.premium || user.level >= 2)}
      />
    )}
  </UserContext.Consumer>
)

export const withOnUser = Component => props => (
  <OnUserContext.Consumer>
    {onUser => <Component {...props} onUser={onUser} />}
  </OnUserContext.Consumer>
)

export const withModals = Component => props => (
  <ModalsContext.Consumer>
    {modals => <Component {...props} modals={modals} />}
  </ModalsContext.Consumer>
)

export const withRemoteConfig = Component => props => (
  <RemoteConfigContext.Consumer>
    {({ remoteConfig, onRemoteConfig, styles }) => (
      <Component
        {...props}
        remoteConfig={remoteConfig}
        onRemoteConfig={onRemoteConfig}
        styles={styles}
      />
    )}
  </RemoteConfigContext.Consumer>
)
