import React, { Fragment } from 'react'
import withLoading from './hoc/withLoading'
import Post from './Post'
import ErrorMessage from './errors/ErrorMessage'
import MyLoader from './MyLoader'
import Comment from './Comment'
import PageTitle from './PageTitle'
import MustLogIn from './MustLogIn'
import { withRemoteConfig } from '../contexts'

class PostPage extends React.PureComponent {
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.props.load()
    }
  }

  render() {
    const { data: post, loading, e, load, remoteConfig } = this.props

    const imageURL =
      (post && post.images && post.images.length > 0 && post.images[0].large) ||
      remoteConfig.logoImageURL

    return (
      <div>
        <ErrorMessage error={e} />
        {loading && <MyLoader />}
        {post && (
          <Fragment>
            <PageTitle
              hidden
              title={post.title}
              description={post.text}
              imageURL={imageURL}
            />
            <Post
              post={post}
              community={post.community}
              load={load}
              allowControls
              showCommunity
            />
            {post.comments ? (
              post.comments.map(comment => (
                <Comment
                  key={comment.id}
                  post={post}
                  comment={comment}
                  load={load}
                />
              ))
            ) : (
              <MustLogIn action="view comments" />
            )}
          </Fragment>
        )}
      </div>
    )
  }
}

export default withLoading(withRemoteConfig(PostPage), {
  paginated: false
})
