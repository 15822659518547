import { Button, Form, FormTextArea, Modal } from 'semantic-ui-react'
import React, { useState } from 'react'

export default ({ open, toggle, loading, onSubmit }) => {
  const [text, setText] = useState('')

  return (
    <Modal
      onClose={toggle}
      closeIcon
      open={open}
      trigger={
        <Button
          size="tiny"
          compact
          basic
          icon="flag"
          content="Flag"
          onClick={toggle}
        />
      }
      header="Flag Content"
      content={
        <Form
          loading={loading}
          className="m2"
          onSubmit={e => {
            e.preventDefault()
            onSubmit(text)
          }}>
          <FormTextArea
            placeholder="Please describe your reason for reporting this content..."
            style={{ fontFamily: 'Lato' }}
            onChange={e => setText(e.target.value)}
            value={text}
          />
          <Button positive fluid content="Submit" />
        </Form>
      }
    />
  )
}
