import React, { Fragment } from 'react'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import { Divider } from 'semantic-ui-react'
import { sortBy } from 'lodash'

const Image = ({ onClick, image, tall }) => {
  const style = tall
    ? {
        width: '100%',
        height: '20em'
      }
    : {
        width: '100%',
        height: '10em'
      }

  return (
    <div style={style} className="background-black pointer" onClick={onClick}>
      <img
        style={style}
        className="object-fit-cover"
        alt="Post/Comment"
        src={image.large}
      />
    </div>
  )
}

export default class extends React.Component {
  state = {
    photoIndex: 0,
    isOpen: false
  }

  render() {
    const { isOpen, photoIndex } = this.state
    let { images, noHero } = this.props

    if (!images || images.length === 0) {
      return null
    }

    images = sortBy(images, 'order')

    const slice = noHero ? [0, 3] : [1, 4]
    const additive = noHero ? 0 : 1

    return (
      <div style={{ width: '100%' }}>
        <Divider />
        {!noHero && (
          <Image
            onClick={() => this.setState({ isOpen: true, photoIndex: 0 })}
            image={images[0]}
            photoIndex={0}
            tall
          />
        )}
        {(images.length > 1 || noHero) && (
          <Fragment>
            <div className="flex" style={{ marginTop: 2 }}>
              {images.slice(slice[0], slice[1]).map((image, i) => (
                <div
                  key={image.id}
                  className="col-4 relative pointer"
                  onClick={() =>
                    this.setState({ isOpen: true, photoIndex: i + additive })
                  }
                  style={{ marginRight: i < 2 ? 2 : 0 }}>
                  <Image image={image} photoIndex={i + additive} />
                  {i === 2 && images.length > slice[1] && (
                    <div
                      className="absolute top-0 left-0 flex justify-center items-center"
                      style={{
                        height: '100%',
                        width: '100%',
                        backgroundColor: 'rgba(0,0,0,0.55)'
                      }}>
                      <h2 className="m0 white">+{images.length - slice[1]}</h2>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </Fragment>
        )}
        {isOpen && (
          <Lightbox
            imageCaption={`${photoIndex + 1}/${images.length}`}
            mainSrc={images[photoIndex].large}
            nextSrc={images[(photoIndex + 1) % images.length].large}
            prevSrc={
              images[(photoIndex + images.length - 1) % images.length].large
            }
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length
              })
            }
          />
        )}
      </div>
    )
  }
}
