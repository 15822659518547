import React from 'react'
import { Form, FormInput } from 'semantic-ui-react'
import PrimaryButton from './PrimaryButton'
import errorToast from '../utils/errorToast'
import api from '../utils/api'

class MakeModerator extends React.Component {
  state = {
    communityId: '',
    loading: false,
    success: null
  }

  onSubmit = async e => {
    e.preventDefault()

    this.setState({ loading: true })
    try {
      await api.post('/moderators', {
        communityId: this.state.communityId,
        userId: this.props.userId
      })
      this.setState({
        loading: false,
        communityId: '',
        success: true
      })
    } catch (e) {
      errorToast(e)
      this.setState({
        loading: false
      })
    }
  }

  render() {
    const { loading, communityId, success } = this.state

    return (
      <Form
        className="mt1"
        size="small"
        loading={loading}
        onSubmit={this.onSubmit}
        success={success}>
        <FormInput
          placeholder="Community ID"
          onChange={e => this.setState({ communityId: e.target.value })}
          value={communityId}
        />
        <PrimaryButton content="Make Moderator" />
      </Form>
    )
  }
}

export default MakeModerator
