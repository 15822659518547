import React from 'react'
import {
  Button,
  Checkbox,
  Confirm,
  Divider,
  Form,
  Modal,
  TextArea
} from 'semantic-ui-react'
import { withOnUser, withUser } from '../../contexts'
import ProfileAvatar from '../ProfileAvatar'
import { toast } from 'react-toastify'
import { flow } from 'lodash'
import api from '../../utils/api'
import { Link, withRouter } from 'react-router-dom'
import Editable from '../Editable'
import errorToast from '../../utils/errorToast'
import Dimmer from '../Dimmer'
import Unverified from '../Unverified'
import PageTitle from '../PageTitle'
import ColoredButton from '../ColoredButton'
import NotLoggedIn from '../errors/NotLoggedIn'
import GoBackHome from '../GoBackHome'
import { getToken, getAndSendToken } from '../../utils/firebase'
import { isWebView } from '../../utils/mobileUserAgent'

class Profile extends React.Component {
  state = {
    overrideImage: null,
    loggingOut: false,
    requestingChangePassword: false,
    showNameModal: false,
    loading: false,
    editBlurb: false,
    blurb: '',
    activeItem: null,
    savingName: false
  }

  setLoading = loading => this.setState({ loading })

  uploadAvatar = async file => {
    const { user } = this.props
    if (!user) {
      return
    }
    const data = new FormData()
    data.append('file', file)
    data.append('userId', user.id)
    this.setLoading(true)
    try {
      await api.post('/images', data)
    } catch (e) {
      errorToast(e)
    } finally {
      this.setLoading(false)
    }
  }

  toggleConfirmSignOut = () =>
    this.setState({ confirmSignOutOpen: !this.state.confirmSignOutOpen })
  toggleName = () =>
    this.setState({
      showNameModal: !this.state.showNameModal,
      firstName: this.props.user.firstName,
      lastName: this.props.user.lastName
    })

  handleSignOut = async () => {
    this.setState({ loggingOut: true })

    let deviceToken = null
    try {
      deviceToken = await getToken()
    } catch (e) {}
    try {
      this.setState({ loggingOut: false, activeItem: null })
      this.props.onUser(null)
      this.props.history.push('/')
    } catch (e) {
      errorToast(e)
      this.setState({ loggingOut: false })
    }
  }

  handleChangePassword = async () => {
    this.setLoading(true)
    try {
      await api.post('/auth/local/reset')
      toast.success('We sent a link to your email to reset your password.')
    } catch (e) {
      errorToast(e)
    } finally {
      this.setLoading(false)
    }
  }

  onInput = ({ target: { name, value } }) => this.setState({ [name]: value })

  onSubmitName = async e => {
    e.preventDefault()
    const { firstName, lastName } = this.state
    if (!firstName || !firstName.length || !lastName || !lastName.length) {
      return
    }
    this.setState({ savingName: true })
    try {
      await api.put('/profile', { firstName, lastName })
      this.setState({ savingName: false, showNameModal: false })
    } catch (e) {
      errorToast(e)
      this.setState({ savingName: false })
    }
  }

  saveBlurb = async () => {
    const { blurb } = this.state
    this.setLoading(true)
    try {
      await api.put('/profile', { blurb })
      this.setState({ loading: false, editBlurb: false })
    } catch (e) {
      errorToast(e)
      this.setLoading(false)
    }
  }

  render() {
    const { showNameModal, loggingOut, loading, editBlurb, blurb } = this.state
    const { user } = this.props

    if (!user) {
      return <NotLoggedIn />
    }

    return (
      <div>
        <PageTitle hidden title="Profile" />
        {loading && <Dimmer />}
        <GoBackHome />
        <div className="mt1" />
        <ProfileAvatar
          user={user}
          large
          editName={this.toggleName}
          editImage
          firstNameOnly={false}
        />
        <Divider hidden />
        <p className="m0 mb1 text-dim">{user.email}</p>
        <Link to={`/users/${user.id}`}>Public Profile</Link>
        <Divider hidden />
        <Editable
          normalView={<p>{user.blurb}</p>}
          editable={true}
          showEditButton={user.blurb}
          editing={editBlurb || !user.blurb}
          hide={() =>
            this.setState({
              editBlurb: false,
              blurb: this.props.user ? this.props.user.blurb : ''
            })
          }
          show={() =>
            this.setState({
              editBlurb: true,
              blurb: this.props.user ? this.props.user.blurb : ''
            })
          }
          showCancel={user.blurb}
          editingView={
            <Form>
              <TextArea
                style={{ fontFamily: 'Lato' }}
                name="blurb"
                value={blurb || ''}
                onChange={this.onInput}
                placeholder="Write something about yourself."
              />
            </Form>
          }
          onSave={this.saveBlurb}
        />
        {!user.verified && <Unverified />}
        <Checkbox
          toggle
          checked={user.emails}
          label="Send Email Notifications"
          onChange={() => {
            api.put('/profile', {
              emails: !user.emails
            })
          }}
        />
        <Divider hidden />
        {window.Notification ? (
          window.Notification.permission !== 'granted' && (
            <Button content="Enable Notifications" onClick={getAndSendToken} />
          )
        ) : (
          <Button
            content="Notifactions not available"
            disabled
            onClick={getAndSendToken}
          />
        )}
        <Divider hidden />
        <Button
          size="small"
          content={'Change Password'}
          onClick={this.handleChangePassword}
        />
        {(process.env.REACT_APP_IS_DEV || !isWebView()) && (
          <ColoredButton
            size="small"
            loading={loggingOut}
            color="red"
            onClick={this.toggleConfirmSignOut}
            content={'Log Out'}
          />
        )}
        <Confirm
          open={this.state.confirmSignOutOpen}
          onCancel={this.toggleConfirmSignOut}
          onConfirm={() => {
            this.toggleConfirmSignOut()
            this.handleSignOut()
          }}
        />
        <Divider hidden />
        <Modal
          className="modal-fix"
          open={showNameModal}
          onClose={this.toggleName}
          closeOnDimmerClick
        >
          <Modal.Content>
            <Form onSubmit={this.onSubmitName} loading={this.state.savingName}>
              <Form.Group widths="equal">
                <Form.Input
                  required
                  label="First Name"
                  name="firstName"
                  placeholder="First Name"
                  onChange={this.onInput}
                  value={this.state.firstName}
                  fluid
                />
                <Form.Input
                  required
                  label="Last Name"
                  name="lastName"
                  placeholder="Last Name"
                  onChange={this.onInput}
                  value={this.state.lastName}
                  fluid
                />
              </Form.Group>
              <Button positive fluid content={'Save'} type={'submit'} />
            </Form>
          </Modal.Content>
        </Modal>
        <input
          hidden
          type="file"
          name="fileInput"
          id="fileInput"
          accept={'image/*'}
          onChange={e => {
            const [file] = e.target.files
            return this.uploadAvatar(file)
          }}
        />
      </div>
    )
  }
}

export default flow(withUser, withOnUser, withRouter)(Profile)
