import React from 'react'
import { withRouter } from 'react-router-dom'
import { getQuery } from './hoc/withLoading'
import { Menu, MenuItem } from 'semantic-ui-react'
import qs from 'qs'
import { withRemoteConfig } from '../contexts'
import { flow } from 'lodash'

class SortTabs extends React.Component {
  render() {
    const {
      history,
      location,
      remoteConfig: { sortOptions: sort },
      community,
      children
    } = this.props
    const query = getQuery(this.props)
    let { order } = query

    if (!order || !sort.options[order]) {
      order = sort.defaultSort
    }

    const mastermind = community.id.toLowerCase() === 'mastermind'

    return (
      <Menu pointing secondary color={community.color}>
        {Object.keys(sort.options).map(key => {
          if (mastermind) {
            if (['top'].includes(key)) return null
          } else if (['parent', 'highValue'].includes(key)) {
            return null
          }

          return (
            <MenuItem
              key={key}
              active={order === key}
              name={sort.options[key]}
              onClick={() => {
                query.order = key
                const queryString = qs.stringify(query)
                history.push(`${location.pathname}?${queryString}`)
              }}
            />
          )
        })}
        {children}
      </Menu>
    )
  }
}

export default flow(
  withRemoteConfig,
  withRouter
)(SortTabs)
