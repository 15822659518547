import React from 'react'
import PageTitle from './PageTitle'

export default () => {
  return (
    <div>
      <PageTitle title="Info" />
      <pre style={{ overflow: 'scroll' }}>
        <p>{navigator.userAgent}</p>
      </pre>
    </div>
  )
}
