import { flow } from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Button, Confirm, Form, FormTextArea } from 'semantic-ui-react'
import { withRemoteConfig, withViewer } from '../contexts'
import api from '../utils/api'
import errorToast from '../utils/errorToast'
import isModerator from '../utils/isModerator'
import uploadSound from '../utils/uploadSound'
import CommentInput from './CommentInput'
import Editable from './Editable'
import FlagButton from './FlagButton'
import FromNow from './FromNow'
import Images from './Images'
import MarkdownParagraph from './MarkdownParagraph'
import More from './More'
import ProfileAvatar from './ProfileAvatar'
import VoteControls from './VoteControls'

class Comment extends React.Component {
  state = {
    showComment: false,
    commenting: false,
    collapsedCommentIds: [],
    images: [],
    deleting: false,
    confirmDelete: false,
    editing: false,
    input: null,
    reportModalOpen: false,
    reportModalLoading: false
  }

  toggleComment = () => this.setState({ showComment: !this.state.showComment })
  toggleEditing = () => this.setState({ editing: !this.state.editing })

  comment = async ({ text, blobURL }) => {
    const { images } = this.state

    this.setState({ commenting: true })
    try {
      let soundURL
      if (blobURL) {
        soundURL = await uploadSound(blobURL)
      }
      await api.post(`/comments/${this.props.comment.id}`, {
        text,
        images,
        soundURL
      })
      this.props.load()
      this.setState({
        text: '',
        images: [],
        commenting: false,
        showComment: false
      })
    } catch (e) {
      errorToast(e)
      this.setState({
        commenting: false
      })
    }
  }

  downvote = async () => {
    try {
      await api.post(`/comments/${this.props.comment.id}/downvote`)
      // this.props.load && this.props.load()
    } catch (e) {
      errorToast(e)
    }
  }

  upvote = async () => {
    try {
      await api.post(`/comments/${this.props.comment.id}/upvote`)
      // this.props.load && this.props.load()
    } catch (e) {
      errorToast(e)
    }
  }

  onCollapse = id => {
    const { collapsedCommentIds } = this.state
    if (!collapsedCommentIds.includes(id)) {
      collapsedCommentIds.push(id)
      this.setState({ collapsedCommentIds })
    }
  }

  onExpand = id => {
    let { collapsedCommentIds } = this.state
    if (collapsedCommentIds.includes(id)) {
      collapsedCommentIds = collapsedCommentIds.filter(
        collapsedCommentId => id !== collapsedCommentId
      )
      this.setState({ collapsedCommentIds })
    }
  }

  toggleConfirmDelete = () =>
    this.setState({ confirmDelete: !this.state.confirmDelete })

  delete = async () => {
    this.setState({
      confirmDelete: false,
      deleting: true
    })

    try {
      await api.delete(`/comments/${this.props.comment.id}`)
      this.props.load()
    } catch (e) {
      errorToast(e)
    } finally {
      this.setState({ deleting: false })
    }
  }

  edit = async () => {
    this.setState({
      deleting: true
    })

    try {
      await api.put(`/comments/${this.props.comment.id}`, {
        text: this.state.input
      })
      this.props.load()
      this.setState({
        deleting: false,
        editing: false
      })
    } catch (e) {
      this.setState({ deleting: false })
      errorToast(e)
    }
  }

  render() {
    const {
      deleting,
      confirmDelete,
      editing,
      input,
      reportModalLoading,
      reportModalOpen
    } = this.state
    const {
      comment,
      post,
      load,
      locked,
      viewer,
      admin,
      remoteConfig
    } = this.props

    const { text, soundURL } = comment
    const showText = text && text.length > 0

    const community = (post && post.community) || {
      color: remoteConfig.colorPrimary
    }

    const collapsedCommentIds = this.state.collapsedCommentIds.concat(
      this.props.collapsedCommentIds || []
    )
    const onExpand = this.props.onExpand || this.onExpand
    const onCollapse = this.props.onCollapse || this.onCollapse
    const collapsed = collapsedCommentIds.includes(comment.id)

    const isMine = viewer && viewer.id === comment.userId
    const deleted = comment.text === '*deleted*'
    const editable = !deleted && isMine
    const deletable =
      !deleted && (isMine || admin || isModerator(viewer, community))
    const premium = viewer && viewer.premium

    const toggleReportModal = () =>
      this.setState({ reportModalOpen: !reportModalOpen })

    return (
      <div className={`comment ${deleting ? 'translucent' : ''}`}>
        <div className="flex">
          <VoteControls
            community={community}
            item={comment}
            upvote={this.upvote}
            downvote={this.downvote}
            collapsed={collapsed}
            onCollapse={onCollapse}
            onExpand={onExpand}
            locked={locked}
            seeVotesUrl={`/comments/${comment.id}/votes`}
          />
          <div className="flex-auto">
            <ProfileAvatar user={comment.user} link />
            <FromNow date={comment.createdAt} edited={comment.editedAt} />
            {soundURL && !deleted && (
              <div>
                <audio src={soundURL} controls />
              </div>
            )}
            <Editable
              normalView={
                showText && (
                  <MarkdownParagraph
                    text={
                      community.isPrivate && !premium
                        ? 'Comment removed because it was created in a premium community.'
                        : text
                    }
                  />
                )
              }
              editable={editable}
              editing={editing}
              toggle={this.toggleEditing}
              showEditButton={false}
              onSave={this.edit}
              editingView={
                <Form>
                  <FormTextArea
                    autoFocus
                    value={input || comment.text}
                    onChange={e => this.setState({ input: e.target.value })}
                  />
                </Form>
              }
            />
          </div>
        </div>
        {comment.images && <Images images={comment.images} noHero />}
        <div className="flex flex-wrap mt1">
          <Button
            color={community.color}
            size="tiny"
            compact
            content="Reply"
            disabled={this.state.showComment}
            onClick={this.toggleComment}
          />
          <Link to={`/comments/${comment.id}`}>
            <Button size="tiny" compact basic>
              Link
            </Button>
          </Link>
          <Link to={`/comments/${comment.id}/votes`}>
            <Button size="tiny" compact basic>
              Votes
            </Button>
          </Link>
          {comment.commentId && (
            <Link to={`/comments/${comment.commentId}`}>
              <Button size="tiny" compact basic>
                Parent
              </Button>
            </Link>
          )}
          <FlagButton
            open={reportModalOpen}
            toggle={toggleReportModal}
            loading={reportModalLoading}
            onSubmit={async text => {
              this.setState({ reportModalLoading: true })
              try {
                await api.post('/reports', {
                  commentId: comment.id,
                  text
                })
                toast.success('Content flagged successfully. Thank you!')
                this.setState({
                  reportModalOpen: false,
                  reportModalLoading: false
                })
              } catch (e) {
                errorToast(e)
                this.setState({ reportModalLoading: false })
              }
            }}
          />
          <More
            onDelete={deletable && this.delete}
            onEdit={editable && this.toggleEditing}
          />
        </div>
        {this.state.showComment && (
          <CommentInput
            loading={this.state.commenting}
            onSubmit={this.comment}
            community={community}
            close={this.toggleComment}
            placeholder="Type your reply..."
            inputId={comment.id}
            images={this.state.images}
            onImages={images => this.setState({ images, r: Math.random() })}
          />
        )}
        {!locked &&
          !collapsed &&
          comment.children &&
          comment.children.length > 0 &&
          comment.children.map(child => (
            <Comment
              onCollapse={onCollapse}
              onExpand={onExpand}
              collapsed={collapsedCommentIds.includes(child.id)}
              collapsedCommentIds={collapsedCommentIds}
              key={child.id}
              post={post}
              comment={child}
              load={load}
              viewer={viewer}
              admin={admin}
            />
          ))}
        {!collapsed && !comment.children && comment.replyCount > 0 && (
          <div className="mt1">
            <Link to={`/comments/${comment.id}`}>
              See {comment.replyCount} more{' '}
              {comment.replyCount === 1 ? 'reply' : 'replies'}
            </Link>
          </div>
        )}
        <Confirm
          open={confirmDelete}
          onCancel={this.toggleConfirmDelete}
          onConfirm={this.delete}
        />
      </div>
    )
  }
}

export default flow(withRemoteConfig, withViewer)(Comment)
