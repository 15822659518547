import { flow } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'
import { withRemoteConfig, withViewer } from '../contexts'
import plural from '../utils/plural'
import profilePicture from '../utils/profilePicture'
import userFullName from '../utils/userFullName'
import AvatarPicture from './AvatarPicture'
import UserBadges from './UserBadges'

function sign(num) {
  if (num > 0) {
    return '+' + num
  }
  return num
}

const ProfileAvatar = ({
  user,
  link,
  large,
  editName,
  editImage,
  bold = true,
  allowExpandImage,
  vote
}) => {
  if (!user) {
    return null
  }

  const src = profilePicture(user)
  const editPictureText = src ? 'Edit Picture' : 'Add Picture'

  const nameClasses = [
    'my0',
    bold ? 'bold' : 'regular',
    large ? 'h3' : 'h4',
    'flex'
  ]
  let fullName = userFullName(user)

  const content = (
    <div className="flex items-center black self-start">
      {vote && (
        <Icon
          size="small"
          name={vote === 'down' ? 'arrow down' : 'arrow up'}
          circular
          inverted
          color={vote === 'down' ? 'red' : 'green'}
        />
      )}
      <div>
        <AvatarPicture
          large={large}
          user={user}
          allowExpandImage={allowExpandImage}
        />
        {editImage && (
          <label
            htmlFor="fileInput"
            className="text-dim text-small absolute pointer"
          >
            {editPictureText}
          </label>
        )}
      </div>
      <div className="flex flex-column ml1">
        {editName && (
          <span className="text-dim text-small pointer" onClick={editName}>
            Edit Name
          </span>
        )}
        <div className="flex items-center flex-wrap">
          <span className={nameClasses.join(' ')}>{fullName}</span>
          <div className="ml1" />
          <UserBadges user={user} />
        </div>
        <span className="text-dim text-small">
          {`${plural(user.postCount, 'Post', 'Posts')} (${sign(
            user.postUpvoteCount - user.postDownvoteCount
          )}), ${plural(user.commentCount, 'Cmt', 'Cmts')} (${sign(
            user.commentUpvoteCount - user.commentDownvoteCount
          )})`}
        </span>
      </div>
    </div>
  )

  return !link ? content : <Link to={`/users/${user.id}`}>{content}</Link>
}

ProfileAvatar.propTypes = {
  user: PropTypes.object,
  link: PropTypes.bool,
  large: PropTypes.bool,
  editName: PropTypes.func,
  editImage: PropTypes.bool,
  vote: PropTypes.oneOf('up', 'down', undefined)
}

export default flow(withViewer, withRemoteConfig)(ProfileAvatar)
