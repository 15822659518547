import ProfileBadge from './ProfileBadge'
import {
  mdiArmFlex,
  mdiHandPeace,
  mdiPoliceBadge,
  mdiStar,
  mdiTrophyVariant
} from '@mdi/js'
import React from 'react'

const MILLISECONDS_30_DAYS = 60000 * 60 * 24 * 30
const minCommentAndPostCount = 20

export default ({ user }) => {
  const createdAt = new Date(user.createdAt)

  const showAdmin = user.level > 0
  const showActive =
    user.postCount + user.commentCount >= minCommentAndPostCount
  const showNew = Date.now() - createdAt.getTime() < MILLISECONDS_30_DAYS

  return (
    <div className="flex items-center">
      {showAdmin ? (
        <ProfileBadge
          colorClass="bg-colorBlack"
          label="Admin"
          path={mdiPoliceBadge}
          description="Administrator of these forums"
        />
      ) : user.isExpert ? (
        <ProfileBadge
          path={mdiTrophyVariant}
          colorClass="bg-colorPurple"
          label="Expert"
          description="Has been marked as an expert by the admins"
        />
      ) : showActive ? (
        <ProfileBadge
          path={mdiArmFlex}
          colorClass="bg-colorBlue"
          label="Active"
          description={`Has at least ${minCommentAndPostCount} combined comments and posts`}
        />
      ) : showNew ? (
        <ProfileBadge
          path={mdiHandPeace}
          colorClass="bg-colorGreen"
          label="New User"
          description="Less than one month old"
        />
      ) : null}
      {user.premium && (
        <span className="profile-badge-mastermind">
          <ProfileBadge
            path={mdiStar}
            colorClass="bg-colorYellow"
            label="Mastermind"
            description="Is subscribed to the mastermind community"
          />
        </span>
      )}
    </div>
  )
}
