import React from 'react'
import { Route, Switch } from 'react-router-dom'
import AboutPage from './AboutPage'
import AddSubPost from './AddSubPost'
import CommentPage from './CommentPage'
import Communities from './Communities'
import CommunityPage from './CommunityPage'
import Content from './Content'
import NotFound from './errors/NotFound'
import Find from './Find'
import Info from './Info'
import Notifications from './Notifications'
import PostPage from './PostPage'
import PostVotesPage from './PostVotesPage'
import Profile from './Profile/Profile'
import ResetPassword from './ResetPassword'
import SearchCommunityPage from './SearchCommunityPage'
import StartThread from './StartThread'
import ThreadPage from './ThreadPage'
import Threads from './Threads'
import UserCommentsPage from './UserCommentsPage'
import UserPage from './UserPage'
import UserPostsPage from './UserPostsPage'
import Verify from './Verify'

export default () => (
  <Content>
    <Switch>
      <Route exact path="/profile" component={Profile} />
      <Route exact path="/notifications" component={Notifications} />
      <Route exact path="/communities" component={Communities} />
      <Route exact path="/communities/:id" component={CommunityPage} />
      <Route
        exact
        path="/communities/:id/search"
        component={SearchCommunityPage}
      />
      <Route exact path="/posts/:id" component={PostPage} />
      <Route exact path="/posts/:id/add" component={AddSubPost} />
      <Route exact path="/posts/:id/votes" component={PostVotesPage} />
      <Route exact path="/comments/:id" component={CommentPage} />
      <Route exact path="/comments/:id/votes" component={PostVotesPage} />
      <Route exact path="/users/find" component={Find} />
      <Route exact path="/users/:id" component={UserPage} />
      <Route exact path="/users/:id/posts" component={UserPostsPage} />
      <Route exact path="/users/:id/comments" component={UserCommentsPage} />
      <Route exact path="/messages/post/:userId" component={StartThread} />
      <Route exact path="/messages/:id" component={ThreadPage} />
      <Route exact path="/messages" component={Threads} />
      <Route exact path="/reset/:id" component={ResetPassword} />
      <Route exact path="/verify/:id" component={Verify} />
      <Route exact path="/info" component={Info} />
      <Route exact path="/about" component={AboutPage} />
      <Route exact path="/" component={Communities} />
      <Route component={NotFound} />
    </Switch>
  </Content>
)
