import React from 'react'
import { Button } from 'semantic-ui-react'
import { ModalsContext } from '../../contexts'
import PageTitle from '../PageTitle'

export default () => (
  <div>
    <PageTitle title="Whoops!" />
    <p>You need to log in to view this content.</p>
    <ModalsContext.Consumer>
      {modals => [
        <Button key={'signUp'} onClick={modals.signUp}>
          Sign Up
        </Button>,
        <Button key={'logIn'} onClick={modals.logIn}>
          Log In
        </Button>
      ]}
    </ModalsContext.Consumer>
  </div>
)
