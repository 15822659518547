import React from 'react'
import PropTypes from 'prop-types'
import { Segment } from 'semantic-ui-react'

const content = (
  <div className="mt1">
    <p>Nothing to show...</p>
  </div>
)

const EmptyState = ({ segment }) =>
  segment ? <Segment>{content}</Segment> : content

EmptyState.propTypes = {
  segment: PropTypes.bool
}

export default EmptyState
