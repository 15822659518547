import algoliasearch from 'algoliasearch'

export default remoteConfig => {
  const algolia = algoliasearch(
    remoteConfig.algoliaAppId,
    remoteConfig.algoliaApiKey
  )

  return {
    users: algolia.initIndex(remoteConfig.algoliaUsersIndex),
    posts: algolia.initIndex(remoteConfig.algoliaPostsIndex)
  }
}
