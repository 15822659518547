import React from 'react'
import { Button, Form, Modal, Segment } from 'semantic-ui-react'
import api from '../../utils/api'
import { withModals, withOnUser, withRemoteConfig } from '../../contexts'
import { flow } from 'lodash'
import errorToast from '../../utils/errorToast'
import NewTab from '../NewTab'
import SocialLoginButtons from '../SocialLoginButtons'

const levelOptions = [
  {
    key: 'user',
    value: 0,
    text: 'User'
  },
  {
    key: 'manager',
    value: 1,
    text: 'Manager'
  },
  {
    key: 'admin',
    value: 2,
    text: 'Admin'
  }
]

class SignUpModal extends React.Component {
  state = {
    loading: false,
    email: null,
    firstName: null,
    lastName: null,
    birthDate: null,
    password: null,
    level: 2
  }

  onLogInClicked = () => {
    this.props.modals.signUp()
    this.props.modals.logIn()
  }

  onSubmit = async e => {
    e.preventDefault()

    const {
      email,
      firstName,
      lastName,
      birthDate,
      password,
      level
    } = this.state
    const user = { email, firstName, lastName, birthDate, password }

    if (this.props.remoteConfig.chooseUserLevel) {
      user.level = level
    }

    this.setLoading(true)
    try {
      await api.post('/auth/local', user)
      const response = await api.post('/auth/local/login', user)
      this.props.modals.signUp()
      this.props.onUser(response.data)
    } catch (e) {
      errorToast(e)
    } finally {
      this.setLoading(false)
    }
  }

  setLoading = loading => this.setState({ loading })

  onInput = ({ target: { name, value } }) => this.setState({ [name]: value })

  render() {
    const { loading, level } = this.state
    const { modals, remoteConfig } = this.props

    return (
      <Modal
        className="modal-fix"
        open={true}
        onClose={modals.signUp}
        size="small"
      >
        <Modal.Content>
          <SocialLoginButtons remoteConfig={remoteConfig} />
          <Form onSubmit={this.onSubmit} loading={loading}>
            <Form.Input
              required
              label="Email"
              onChange={this.onInput}
              name="email"
              type="email"
              placeholder="Email"
            />
            <Form.Group widths="equal">
              <Form.Input
                required
                label="First Name"
                name="firstName"
                placeholder="First Name"
                onChange={this.onInput}
                fluid
              />
              <Form.Input
                required
                label="Last Name"
                name="lastName"
                placeholder="Last Name"
                onChange={this.onInput}
                fluid
              />
            </Form.Group>
            <Form.Input
              required
              label="Password"
              onChange={this.onInput}
              name="password"
              type="password"
              placeholder="Password"
            />
            <Form.Input
              required
              label="Birth Date"
              onChange={this.onInput}
              name="birthDate"
              type="date"
              placeholder="Birth Date"
            />
            {remoteConfig.chooseUserLevel && (
              <Form.Select
                options={levelOptions}
                label="User Level (This won't show in production.)"
                value={level}
                onChange={(e, { value: level }) => {
                  this.setState({ level })
                }}
              />
            )}
            <div className="my1 center">
              By signing up, you agree to our{' '}
              <NewTab href="/terms">Terms of Service</NewTab>
              {'.'}
            </div>
            {remoteConfig.minimumAge && (
              <div className="my1 center">
                You also confirm that you are at least {remoteConfig.minimumAge}{' '}
                years old.
              </div>
            )}
            <Button primary fluid>
              Sign Up
            </Button>
          </Form>
          <Segment textAlign="center" basic>
            <p>Already have an account?</p>
            <Button size="tiny" basic onClick={this.onLogInClicked}>
              Log In
            </Button>
          </Segment>
        </Modal.Content>
      </Modal>
    )
  }
}

export default flow(withModals, withOnUser, withRemoteConfig)(SignUpModal)
