import React from 'react'
import Dimmer from './Dimmer'
import PageTitle from './PageTitle'
import ErrorMessage from './errors/ErrorMessage'
import { withUser } from '../contexts'
import { Message } from 'semantic-ui-react'
import api from '../utils/api'

class Verify extends React.Component {
  constructor(props) {
    super(props)

    const verified = props.user && props.user.verified
    this.state = {
      loading: false,
      verified,
      e: null
    }

    if (!verified) this.verify()
  }

  verify = async () => {
    const { id } = this.props.match.params

    this.setState({ loading: true })
    try {
      await api.post(`/auth/verify/${id}`)
      this.setState({ loading: false, verified: true })
    } catch (e) {
      this.setState({ loading: false, e })
    }
  }

  render() {
    const { loading, verified, e } = this.state

    return (
      <div>
        {loading && <Dimmer />}
        <PageTitle title="Verify Email Address" />
        {verified && (
          <Message
            success
            header="Verified"
            content="Your account is verified. Thanks!"
          />
        )}
        {e && <ErrorMessage error={e} />}
      </div>
    )
  }
}

export default withUser(Verify)
