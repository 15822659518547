import api from './api'

export default async function(blobURL) {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest()
    xhr.open('GET', blobURL, true)
    xhr.responseType = 'blob'
    xhr.onload = async function(e) {
      if (this.status === 200) {
        var myBlob = this.response

        const data = new FormData()
        data.append('file', myBlob)

        const response = await api.post('/sounds', data)
        return resolve(response.data)
        // myBlob is now the blob that the object URL pointed to.
      }
    }
    xhr.send()
  })
}
