import React, { useState } from 'react'
import { Button, Confirm } from 'semantic-ui-react'
import api from '../utils/api'
import errorToast from '../utils/errorToast'

export default ({ userId, load }) => {
  const [loading, setLoading] = useState(false)
  const [confirm, setConfirm] = useState(false)

  return (
    <>
      <Button
        negative
        loading={loading}
        onClick={() => setConfirm(true)}
        content="Ban"
      />
      <Confirm
        open={confirm}
        onConfirm={() => {
          setConfirm(false)
          setLoading(true)
          api
            .post(`/admin/users/${userId}/ban`)
            .then(load)
            .catch(errorToast)
            .finally(() => setLoading(false))
        }}
        onCancel={() => setConfirm(false)}
      />
    </>
  )
}
