import React from 'react'
import { Button, Menu, MenuItem, Popup } from 'semantic-ui-react'

const More = ({ onSave, onReport, onDelete, onEdit }) =>
  onSave || onReport || onDelete || onEdit ? (
    <Popup
      basic
      hoverable
      inverted
      trigger={<Button basic compact size="tiny" content="More" />}
      content={
        <Menu vertical inverted size="small">
          {onReport && <MenuItem content="Report" onClick={onReport} />}
          {onSave && <MenuItem content="Save" onClick={onSave} />}
          {onDelete && <MenuItem content="Delete" onClick={onDelete} />}
          {onEdit && <MenuItem content="Edit" onClick={onEdit} />}
        </Menu>
      }
    />
  ) : null

export default More
