import React, { Fragment } from 'react'
import { Icon, Label, Menu, MenuItem, Popup } from 'semantic-ui-react'
import { withModals, withRemoteConfig, withUser } from '../contexts'
import { flow } from 'lodash'
import { Link } from 'react-router-dom'
import MediaQuery from 'react-responsive'
import { isAppleWebView } from '../utils/mobileUserAgent'

function badgeColor(user) {
  return 'red'
}

function imageURL(user, remoteConfig) {
  return user && user.premium && remoteConfig.premiumTextLogoImageURL
    ? remoteConfig.premiumTextLogoImageURL
    : remoteConfig.textLogoImageURL
}

function backgroundColor(user, remoteConfig) {
  return user && user.premium && remoteConfig.colorPremiumToolbar
    ? remoteConfig.colorPremiumToolbar
    : remoteConfig.colorPrimary
}

const Item = ({ to, text }) => (
  <Link to={to}>
    <div className="flex items-center toolbar-item px1 white">{text}</div>
  </Link>
)

const Toolbar = ({ user, modals, remoteConfig }) => {
  const links = remoteConfig.toolbarLinks.map(tl => {
    return {
      key: tl.id,
      as: 'a',
      target: 'blank',
      rel: 'noopener noreferrer',
      href: tl.url,
      name: tl.text
    }
  })
  return (
    <div
      className="toolbar flex white"
      style={{ backgroundColor: backgroundColor(user, remoteConfig) }}
    >
      <Link to="/">
        <div className="white flex items-center pl2 pr1 fit h-100">
          <img
            width={user ? (user.premium ? 180 : 120) : 120}
            className="object-fit-contain"
            src={imageURL(user, remoteConfig)}
            alt={remoteConfig.appName}
          />
        </div>
      </Link>
      <Link className="text-dim" to="/info">
        <div className="h-100 px1" />
      </Link>
      <div className="flex-auto" />
      {user ? (
        <Fragment>
          <Link to="/notifications">
            <div className="flex items-center toolbar-item px1 white">
              <Icon name="bell" size="large" />
              {user.unreadNotificationCount > 0 && (
                <Label
                  size="tiny"
                  color={badgeColor(user)}
                  content={user.unreadNotificationCount}
                />
              )}
            </div>
          </Link>
          <Link to="/messages">
            <div className="flex items-center toolbar-item px1 white">
              <Icon name="chat" size="large" />
              {user.unreadMessageCount > 0 && (
                <Label
                  size="tiny"
                  color={badgeColor(user)}
                  content={user.unreadMessageCount}
                />
              )}
            </div>
          </Link>
        </Fragment>
      ) : (
        <div className="flex items-center px1 pointer" onClick={modals.signUp}>
          Sign Up
        </div>
      )}
      <MediaQuery minWidth={512}>
        {user ? (
          <Item to="/profile" text={user.firstName} />
        ) : (
          <div className="flex items-center px1 pointer" onClick={modals.logIn}>
            Log In
          </div>
        )}
      </MediaQuery>
      <ToolbarLinks
        links={links}
        remoteConfig={remoteConfig}
        user={user}
        modals={modals}
      />
      <div className="toolbar-shadow" />
    </div>
  )
}

const timeoutLength = 5000

class ToolbarLinks extends React.Component {
  state = {
    isOpen: false
  }

  handleOpen = () => {
    this.setState({ isOpen: true })

    this.timeout = setTimeout(() => {
      this.setState({ isOpen: false })
    }, timeoutLength)
  }

  handleClose = () => {
    this.setState({ isOpen: false })
    clearTimeout(this.timeout)
  }

  render() {
    const { links, user, modals } = this.props

    return (
      <Popup
        basic
        inverted
        content={
          <Menu vertical inverted>
            {user ? (
              <Fragment>
                {/*{user.level > 0 && <MenuItem as={Link} to="/admin" name="Admin" onClick={this.handleClose}/>}*/}
                <MenuItem
                  as={Link}
                  to="/notifications"
                  onClick={this.handleClose}
                >
                  Notifications
                  {user.unreadNotificationCount > 0 && (
                    <Label
                      size="tiny"
                      color={badgeColor(user)}
                      content={user.unreadNotificationCount}
                    />
                  )}
                </MenuItem>
                <MenuItem as={Link} to="/messages" onClick={this.handleClose}>
                  Messaging
                  {user.unreadMessageCount > 0 && (
                    <Label
                      size="tiny"
                      color={badgeColor(user)}
                      content={user.unreadMessageCount}
                    />
                  )}
                </MenuItem>
                <MenuItem
                  as={Link}
                  to="/profile"
                  name={user.firstName}
                  onClick={this.handleClose}
                />
              </Fragment>
            ) : (
              <Fragment>
                <MenuItem
                  onClick={() => {
                    modals.logIn()
                    this.handleClose()
                  }}
                  name="Log In"
                />
                <MenuItem
                  onClick={() => {
                    modals.signUp()
                    this.handleClose()
                  }}
                  name="Sign Up"
                />
              </Fragment>
            )}
            {!isAppleWebView() &&
              links.map(link => <MenuItem {...link} icon="external link" />)}
            <MenuItem
              as={Link}
              to="/about"
              name="About"
              onClick={this.handleClose}
            />
          </Menu>
        }
        trigger={
          <div
            onClick={() => {
              if (this.state.isOpen) {
                this.handleClose()
              } else {
                this.handleOpen()
              }
            }}
            className="flex items-center px1 pointer"
          >
            <i className="material-icons white" style={{ userSelect: 'none' }}>
              menu
            </i>
          </div>
        }
        open={this.state.isOpen}
      />
    )
  }
}

export default flow(withModals, withUser, withRemoteConfig)(Toolbar)
