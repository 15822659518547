import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import {
  ModalsContext,
  OnUserContext,
  RemoteConfigContext,
  UserContext
} from '../contexts'

export default props => (
  <BrowserRouter>
    <OnUserContext.Provider value={props.onUser}>
      <UserContext.Provider value={props.user}>
        <ModalsContext.Provider value={props.modals}>
          <RemoteConfigContext.Provider
            value={{
              remoteConfig: props.remoteConfig,
              onRemoteConfig: props.onRemoteConfig
            }}>
            {props.children}
          </RemoteConfigContext.Provider>
        </ModalsContext.Provider>
      </UserContext.Provider>
    </OnUserContext.Provider>
  </BrowserRouter>
)
