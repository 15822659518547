export default (user, community) => {
  if (!user || !community || !community.moderators) {
    return false
  }

  if (user.level > 1) {
    return true
  }

  const moderatorUserIds = community.moderators.map(
    moderator => moderator.userId
  )
  return moderatorUserIds.includes(user.id)
}
