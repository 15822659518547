import React, { Fragment, useContext } from 'react'
import { Helmet } from 'react-helmet'
import { RemoteConfigContext } from '../contexts'

export default ({
  title,
  description,
  imageURL,
  className,
  hidden = false
}) => {
  const { remoteConfig } = useContext(RemoteConfigContext)

  let headTitle
  if (!title) {
    headTitle = remoteConfig.appName
  } else {
    headTitle = `${title} | ${remoteConfig.appName}`
  }

  if (!description) {
    description =
      'Join the #1 Pickup (PUA) forums. Get all of your dating questions answered for free by guys who actually get laid.'
  }
  if (!imageURL) {
    imageURL = imageURL = '%PUBLIC_URL%/images/feature-image.jpeg'
  }
  return (
    <Fragment>
      {!hidden && <h1 className={className}>{title}</h1>}
      <Helmet>
        <title>{headTitle}</title>
        <meta name="og:title" content={headTitle} />
        <meta name="description" content={description} />
        <meta
          property="og:description"
          name="og:description"
          content={description}
        />
        <meta property="og:image" name="og:image" content={imageURL} />
        <meta property="image" name="image" content={imageURL} />
        <meta
          property="twitter:image"
          name="twitter:image"
          content={imageURL}
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta
          property="og:keywords"
          content="dating forums,PUA,forums,best PUA forums,pickup artist forums,MPUA forum,seducer forums,seduction forums,pickup community forum,pickup artist techniques,dating picking up girls forums,pickup community,PUA community"
        />
      </Helmet>
    </Fragment>
  )
}
