import React from 'react'
import { Popup, Statistic } from 'semantic-ui-react'
import shortenNumber from '../utils/shortenNumber'
import plural from '../utils/plural'

export default ({ community, size = 'medium', inverted }) => (
  <Popup
    content={plural(community.subscriptionCount, 'Sub', 'Subs')}
    trigger={
      <Statistic
        inverted={inverted}
        style={{ margin: 0 }}
        size={size}
        color={community.color}
        value={shortenNumber(community.subscriptionCount)}
        label={plural(community.subscriptionCount, 'Member', 'Members', true)}
      />
    }
  />
)
