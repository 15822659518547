import React from 'react'
import { withRouter } from 'react-router-dom'
import { Divider, Form, FormInput } from 'semantic-ui-react'
import { withRemoteConfig } from '../contexts'
import algoliaFromRemoteConfig from '../utils/algoliaFromRemoteConfig'
import api from '../utils/api'
import CommunityHeader from './CommunityHeader'
import EmptyState from './EmptyState'
import ErrorMessage from './errors/ErrorMessage'
import MyLoader from './MyLoader'
import Post from './Post'
import qs from 'querystring'

class SearchCommunityPage extends React.Component {
  state = {
    results: [],
    searching: false,
    error: undefined,
    hasSearched: false,
    community: null
  }

  componentDidMount() {
    const { id } = this.props.match.params

    api.get(`/communities/${id}`).then(response => {
      this.setState({
        community: response.data
      })
    })

    const search = this.searchFromProps(this.props)
    if (search) {
      this.doSearch()
    }
  }

  componentDidUpdate(prevProps) {
    const search = this.searchFromProps(this.props)
    const prevSearch = this.searchFromProps(prevProps)

    if (search !== prevSearch) {
      this.doSearch()
    }
  }

  searchFromProps = props => {
    const query = qs.parse(props.location.search.substring(1))
    return query.q || ''
  }

  doSearch = async () => {
    const search = this.searchFromProps(this.props)
    const {
      match: {
        params: { id: communityId }
      },
      remoteConfig
    } = this.props

    if (search.length < 3) {
      return this.setState({
        searching: false,
        results: [],
        hasSearched: false
      })
    }

    try {
      const { hits } = await algoliaFromRemoteConfig(remoteConfig).posts.search(
        search,
        {
          facetFilters: [`communityId:${communityId}`]
        }
      )

      this.setState({
        results: hits.map(hit => {
          return {
            id: hit.objectID,
            ...hit,
            objectID: undefined
          }
        })
      })
    } catch (e) {
      this.setState({
        error: e
      })
    } finally {
      this.setState({
        searching: false,
        hasSearched: true
      })
    }
  }

  render() {
    const { history } = this.props
    const { results, searching, hasSearched, community } = this.state
    const error = this.props.error || this.state.error

    return (
      <div>
        <ErrorMessage error={error} />
        {community ? (
          <>
            <CommunityHeader community={community} large />
            <Divider />
            <Form onSubmit={e => e.preventDefault()}>
              <FormInput
                value={this.searchFromProps(this.props)}
                icon="search"
                placeholder="Search... (min. 3 char.)"
                loading={searching}
                onChange={e => {
                  history.replace(
                    `${history.location.pathname}?q=${e.target.value}`
                  )
                }}
              />
            </Form>
            <Divider hidden />
            {results && results.length > 0 ? (
              results.map(post => (
                <Post key={post.id} post={post} community={community} />
              ))
            ) : hasSearched ? (
              <EmptyState />
            ) : null}
          </>
        ) : (
          <MyLoader />
        )}
      </div>
    )
  }
}

export default withRemoteConfig(withRouter(SearchCommunityPage))
