import React from 'react'
import withLoading from './hoc/withLoading'
import PageTitle from './PageTitle'
import MyLoader from './MyLoader'
import ErrorMessage from './errors/ErrorMessage'
import EmptyState from './EmptyState'
import Post from './Post'
import Comment from './Comment'

class Notifications extends React.Component {
  render() {
    const { data, loading, e, load } = this.props

    return (
      <div>
        <PageTitle title="Notifications" />
        {loading && <MyLoader />}
        <ErrorMessage error={e} />
        {data &&
          (data.rows && data.rows.length > 0 ? (
            data.rows.map(row => {
              if (row.post) {
                return (
                  <Post
                    key={row.id}
                    post={row.post}
                    community={row.post.community}
                    showCommunity
                    load={load}
                  />
                )
              }
              if (row.comment) {
                return (
                  <Comment
                    key={row.id}
                    comment={row.comment}
                    post={row.comment.rootPost}
                    load={load}
                    communitySegment
                  />
                )
              }

              return null
            })
          ) : (
            <EmptyState />
          ))}
      </div>
    )
  }
}

export default withLoading(Notifications, {
  paginated: true
})
