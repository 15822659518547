const userAgent = window.navigator.userAgent.toLowerCase(),
  safari = /safari/.test(userAgent),
  ios = /iphone|ipod|ipad|macintosh/.test(userAgent)

export function isWebView() {
  const androidWebView = navigator.userAgent.includes('wv')

  if (androidWebView) {
    return true
  }

  return isAppleWebView()
}

export function isAppleWebView() {
  if (ios) {
    if (safari) {
      //browser
    } else if (!safari) {
      return true
    }
  } else {
    //not iOS
  }

  return false
}
