export default e => {
  console.error(e)
  if (!e || !e.response) {
    return 'Whoops, something went wrong. Please try again later.'
  }
  if (!e.response.data || !e.response.data.error) {
    const { status } = e.response
    if (status === 401) {
      return "Looks like you're not allowed to do that."
    } else if (status === 404) {
      return "We couldn't find what you're looking for."
    } else if (status === 428) {
      return 'Your email address is not verified. Please check your email.'
    } else {
      return 'Whoops, something went wrong. Please try again later.'
    }
  }

  return e.response.data.error
}
