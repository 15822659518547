import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  Button,
  Checkbox,
  Confirm,
  Divider,
  Input,
  Menu,
  MenuItem,
  Segment,
  SegmentGroup,
  Statistic
} from 'semantic-ui-react'
import { withViewer } from '../contexts'
import api from '../utils/api'
import errorToast from '../utils/errorToast'
import plural from '../utils/plural'
import BanButton from './BanButton'
import Comment from './Comment'
import Dimmer from './Dimmer'
import Editable from './Editable'
import EmptyState from './EmptyState'
import ErrorMessage from './errors/ErrorMessage'
import GoBackHome from './GoBackHome'
import withLoading from './hoc/withLoading'
import MakeModerator from './MakeModerator'
import MustLogIn from './MustLogIn'
import PageTitle from './PageTitle'
import Post from './Post'
import ProfileAvatar from './ProfileAvatar'

function tabPath(id, tab) {
  return `/users/${id}#${tab}`
}

const UserPage = ({
  data,
  loading,
  e,
  load,
  match: {
    params: { id }
  },
  history,
  location: { hash },
  viewer,
  admin
}) => {
  const [editingEmail, setEditingEmail] = useState(false)
  let [updatedEmail, setUpdatedEmail] = useState(undefined)
  const [saving, setSaving] = useState(false)
  const [blockOpen, setBlockOpen] = useState(false)
  const [blockLoading, setBlockLoading] = useState(false)

  if (updatedEmail === undefined && data) {
    updatedEmail = data.email
  }

  return (
    <div>
      <ErrorMessage error={e} />
      {loading && <Dimmer />}
      {data && (
        <div>
          <PageTitle hidden title={data.firstName} />
          <GoBackHome />
          <div className="mt1" />
          <ProfileAvatar large user={data} allowExpandImage />
          {admin && (
            <Fragment>
              <Segment loading={saving}>
                <Checkbox
                  checked={data.isReviewer}
                  label="Profile Reviewer"
                  onChange={async (e, { checked: isReviewer }) => {
                    setSaving(true)
                    api
                      .put(`/admin/users/${data.id}`, { isReviewer })
                      .then(load)
                      .catch(errorToast)
                      .finally(() => setSaving(false))
                  }}
                />
                <div className="mt1" />
                <Checkbox
                  checked={data.isExpert}
                  label="Expert User"
                  onChange={async (e, { checked: isExpert }) => {
                    setSaving(true)
                    api
                      .put(`/admin/users/${data.id}`, { isExpert })
                      .then(load)
                      .catch(errorToast)
                      .finally(() => setSaving(false))
                  }}
                />
                <div className="mt1" />
                <BanButton userId={data.id} load={load} />
                <div className="mt1" />
                <Editable
                  editable
                  editing={editingEmail}
                  normalView={<p className="muted">{data.email}</p>}
                  editingView={
                    <Input
                      value={updatedEmail}
                      fluid
                      onChange={(e, { value }) => setUpdatedEmail(value)}
                    />
                  }
                  onSave={async () => {
                    setSaving(true)
                    try {
                      await api.post(`/admin/users/${data.id}/email`, {
                        email: updatedEmail
                      })
                      setEditingEmail(false)
                      load()
                    } catch (e) {
                      errorToast(e)
                    } finally {
                      setSaving(false)
                    }
                  }}
                  toggle={() => setEditingEmail(!editingEmail)}
                />
              </Segment>
              <div className="mt1" />
              <Segment>
                <MakeModerator userId={data.id} />
              </Segment>
            </Fragment>
          )}
          {data.blurb && (
            <Fragment>
              <p className="mt1">{data.blurb}</p>
            </Fragment>
          )}
          <Divider hidden />
          <div>
            {viewer && viewer.id !== data.id && (
              <Link to={`/messages/post/${data.id}`}>
                <Button content={`Message ${data.firstName}`} />
              </Link>
            )}
            <Button
              content={data.blocked ? 'Unblock' : 'Block'}
              loading={blockLoading}
              icon={data.blocked ? undefined : 'ban'}
              disabled={viewer && viewer.id === data.id}
              onClick={async () => {
                if (!viewer) {
                  return errorToast('You must be logged in to block people!')
                }

                setBlockOpen(true)
              }}
            />
            <Confirm
              open={blockOpen}
              onCancel={() => setBlockOpen(false)}
              onConfirm={async () => {
                setBlockLoading(true)
                setBlockOpen(false)

                try {
                  await api.post('/blocks', {
                    blockedUserId: data.id,
                    blocked: !data.blocked
                  })
                  toast.success(
                    data.blocked
                      ? 'This user has been unblocked.'
                      : 'This user has been blocked.'
                  )
                  load()
                } catch (e) {
                  errorToast(e)
                } finally {
                  setBlockLoading(false)
                }
              }}
            />
          </div>
          <SegmentGroup>
            <Segment>
              <Link to={`/users/${id}/posts`}>
                <div className="flex">
                  <Statistic
                    className="col-3 content-center"
                    size="tiny"
                    label={plural(data.postCount, 'Post', 'Posts', true)}
                    value={data.postCount}
                  />
                  <Statistic
                    className="col-3 content-center"
                    size="tiny"
                    label={plural(
                      data.postUpvoteCount - data.postDownvoteCount,
                      'Net Vote',
                      'Net Votes',
                      true
                    )}
                    value={data.postUpvoteCount - data.postDownvoteCount}
                  />
                  <Statistic
                    className="col-3 content-center"
                    size="tiny"
                    color="green"
                    label={plural(
                      data.postUpvoteCount,
                      'Upvote',
                      'Upvotes',
                      true
                    )}
                    value={data.postUpvoteCount}
                  />
                  <Statistic
                    className="col-3 content-center"
                    size="tiny"
                    color="red"
                    label={plural(
                      data.postDownvoteCount,
                      'Downvote',
                      'Downvotes',
                      true
                    )}
                    value={data.postDownvoteCount}
                  />
                </div>
              </Link>
            </Segment>
            <Segment>
              <Link to={`/users/${id}/comments`}>
                <div className="flex">
                  <Statistic
                    className="col-3 content-center"
                    size="tiny"
                    label={plural(
                      data.commentCount,
                      'Comment',
                      'Comments',
                      true
                    )}
                    value={data.commentCount}
                  />
                  <Statistic
                    className="col-3 content-center"
                    size="tiny"
                    label={plural(
                      data.commentUpvoteCount - data.commentDownvoteCount,
                      'Net Vote',
                      'Net Votes',
                      true
                    )}
                    value={data.commentUpvoteCount - data.commentDownvoteCount}
                  />
                  <Statistic
                    className="col-3 content-center"
                    size="tiny"
                    color="green"
                    label={plural(
                      data.commentUpvoteCount,
                      'Upvote',
                      'Upvotes',
                      true
                    )}
                    value={data.commentUpvoteCount}
                  />
                  <Statistic
                    className="col-3 content-center"
                    size="tiny"
                    color="red"
                    label={plural(
                      data.commentDownvoteCount,
                      'Downvote',
                      'Downvotes',
                      true
                    )}
                    value={data.commentDownvoteCount}
                  />
                </div>
              </Link>
            </Segment>
          </SegmentGroup>
          <Menu pointing secondary>
            <MenuItem
              name="Posts"
              active={!hash || hash === '#posts'}
              onClick={() => history.replace(tabPath(id, 'posts'))}
            />
            <MenuItem
              name="Comments"
              active={hash === '#comments'}
              onClick={() => history.replace(tabPath(id, 'comments'))}
            />
          </Menu>
          {(!hash || hash === '#posts') &&
            (!data.posts ? (
              <MustLogIn action="view posts" />
            ) : data.posts.length > 0 ? (
              <div>
                {data.posts.map(post => (
                  <Post
                    key={post.id}
                    load={load}
                    post={post}
                    community={post.community}
                    showCommunity
                  />
                ))}
                <Link to={`/users/${id}/posts`}>See all posts</Link>
              </div>
            ) : (
              <EmptyState />
            ))}
          {hash === '#comments' &&
            (!data.comments ? (
              <MustLogIn action="view comments" />
            ) : data.comments.length > 0 ? (
              <div>
                {data.comments.map(comment => (
                  <Comment
                    key={comment.id}
                    comment={comment}
                    post={comment.rootPost}
                    locked
                    load={load}
                  />
                ))}
                <Link to={`/users/${id}/comments`}>See all comments</Link>
              </div>
            ) : (
              <EmptyState />
            ))}
        </div>
      )}
    </div>
  )
}

export default withViewer(
  withLoading(UserPage, {
    paginated: false
  })
)
