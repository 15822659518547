import { Link } from 'react-router-dom'
import React from 'react'
import UserBadges from './UserBadges'
import userFullName from '../utils/userFullName'

export default ({ user }) => {
  return (
    <div className="flex items-center">
      <Link to={`/users/${user.id}`}>
        <span className="hover-underline text-small bold">
          {userFullName(user)}
        </span>
      </Link>
      <div className="ml1" />
      <UserBadges user={user} />
    </div>
  )
}
