import React from 'react'
import PrimaryButton from './PrimaryButton'
import { Link } from 'react-router-dom'
import { withRemoteConfig } from '../contexts'

const GoBackHome = ({ remoteConfig }) =>
  remoteConfig.showGoBackHome ? (
    <Link to="/">
      <PrimaryButton content="Go Back Home" />
    </Link>
  ) : null

export default withRemoteConfig(GoBackHome)
