import React from 'react'
import ErrorMessage from './errors/ErrorMessage'
import withLoading from './hoc/withLoading'
import MyLoader from './MyLoader'
import PageTitle from './PageTitle'
import ProfileAvatar from './ProfileAvatar'

class PostVotesPage extends React.Component {
  render() {
    const { loading, data, e } = this.props

    return (
      <div>
        <PageTitle hidden title="Votes" />
        {loading && <MyLoader />}
        <ErrorMessage error={e} />
        {data &&
          data.map(vote => {
            return (
              <ProfileAvatar
                key={vote.id.toString()}
                user={vote.user}
                link
                vote={vote.up ? 'up' : 'down'}
              />
            )
          })}
      </div>
    )
  }
}

export default withLoading(PostVotesPage, {
  paginated: false
})
