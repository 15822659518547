import React from 'react'
import { Form } from 'semantic-ui-react'

const colors = [
  'red',
  'orange',
  'yellow',
  'olive',
  'green',
  'teal',
  'blue',
  'violet',
  'purple',
  'pink',
  'brown',
  'grey',
  'black'
]

const options = colors.sort().map(color => ({
  key: color,
  value: color,
  text: color[0].toUpperCase() + color.substring(1)
}))

export default ({ color, onSelect }) => (
  <Form.Select
    options={options}
    placeholder="Color"
    label="Color"
    value={color}
    onChange={(e, { value }) => onSelect(value)}
  />
)
