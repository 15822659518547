import React from 'react'
import PropTypes from 'prop-types'
import { Dimmer as SemanticDimmer, Loader } from 'semantic-ui-react'

const Dimmer = ({ noText, text }) => (
  <SemanticDimmer active style={{ position: 'fixed' }}>
    <Loader content={noText ? null : text || 'Please wait'} />
  </SemanticDimmer>
)

Dimmer.propTypes = {
  noText: PropTypes.bool,
  text: PropTypes.string
}

export default Dimmer
