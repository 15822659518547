import { flow } from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'
import { Popup } from 'semantic-ui-react'
import { withModals, withViewer } from '../contexts'
import colorClass from '../utils/colorClass'
import nFormatter from '../utils/nFormatter'
import plural from '../utils/plural'

class VoteControls extends React.Component {
  state = {
    voted: null,
    score: null
  }

  render() {
    const {
      community,
      item,
      collapsed,
      onCollapse,
      onExpand,
      viewer,
      modals,
      locked,
      seeVotesUrl
    } = this.props
    let { voted, score } = this.state

    const color = colorClass(community.color)
    const upvoted = voted !== null ? voted === 1 : item.upvoted
    const downvoted = voted !== null ? voted === -1 : item.downvoted

    if (score === null) {
      if (isNaN(item.score)) {
        return null
      }
      score = item.score
    }

    return (
      <div className="center mr1">
        <i
          style={{
            fontSize: '20pt'
          }}
          onClick={() => {
            if (!viewer) {
              return modals.signUp()
            }

            this.setState({
              voted: upvoted ? 0 : 1,
              score: upvoted ? score - 1 : downvoted ? score + 2 : score + 1
            })
            this.props.upvote()
          }}
          className={`material-icons pointer noselect ${upvoted ? color : ''}`}
        >
          keyboard_arrow_up
        </i>
        <Popup
          on="click"
          trigger={<h3 className="m0 hover-underline">{nFormatter(score)}</h3>}
          content={
            <div>
              <p>
                {`${nFormatter(item.upvotes)} ${plural(
                  item.upvotes,
                  'Upvote',
                  'Upvotes',
                  true
                )}, ${nFormatter(item.downvotes)} ${plural(
                  item.downvotes,
                  'Downvote',
                  'Downvotes',
                  true
                )}`}
              </p>
              {seeVotesUrl && (
                <p>
                  <Link to={seeVotesUrl}>
                    <span className="hover-underline">See Votes</span>
                  </Link>
                </p>
              )}
            </div>
          }
        />
        <i
          style={{
            fontSize: '20pt'
          }}
          onClick={() => {
            if (!viewer) {
              return modals.signUp()
            }

            this.setState({
              voted: downvoted ? 0 : -1,
              score: downvoted ? score + 1 : upvoted ? score - 2 : score - 1
            })
            this.props.downvote()
          }}
          className={`material-icons pointer noselect ${
            downvoted ? color : ''
          }`}
        >
          keyboard_arrow_down
        </i>
        <br />
        {!locked && item.children && item.children.length > 0 && (
          <i
            style={{
              fontSize: '14pt'
            }}
            onClick={
              collapsed ? () => onExpand(item.id) : () => onCollapse(item.id)
            }
            className="material-icons pointer noselect"
          >
            {collapsed ? 'add' : 'remove'}
          </i>
        )}
      </div>
    )
  }
}

export default flow(withViewer, withModals)(VoteControls)
