import React, { Fragment } from 'react'

const SocialLoginButtons = ({ remoteConfig }) => (
  <Fragment>
    <a
      className="ui fluid button google white hover-lighter"
      href={remoteConfig.baseURL + '/api/auth/google'}>
      Sign in with Google
    </a>
    <div className="mt1" />
    <a
      className="mt1 ui fluid blue button facebook white hover-lighter"
      href={remoteConfig.baseURL + '/api/auth/facebook'}>
      Sign in with Facebook
    </a>
    <div className="mt1" />
  </Fragment>
)

export default SocialLoginButtons
