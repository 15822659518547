import React from 'react'
import { Button, Form } from 'semantic-ui-react'
import api from '../utils/api'
import errorToast from '../utils/errorToast'
import ImagesEditor, { UploadButton } from './ImagesEditor'
import { flow } from 'lodash'
import { withModals, withViewer } from '../contexts'
import FormattingHelp from './FormattingHelp'
import TagUserInput from './TagUserInput'

const inputId = 'postFormInput'

class PostForm extends React.PureComponent {
  state = {
    title: '',
    text: '',
    loading: false,
    images: [],
    tagOpen: false
  }

  onSubmit = async () => {
    if (!this.props.viewer) {
      return this.props.modals.signUp()
    }
    const { title, text, images } = this.state
    const communityId = this.props.community.id
    const imageIds = images.map(image => image.id)

    this.setState({ loading: true })
    try {
      await api.post('/posts', { title, text, communityId, imageIds })
      this.setState({ loading: false, title: '', text: '' })
      this.props.onComplete()
    } catch (e) {
      errorToast(e)
      this.setState({ loading: false })
    }
  }

  render() {
    const { images, tagOpen, text } = this.state

    return (
      <Form loading={this.state.loading} onSubmit={this.onSubmit}>
        <Form.Input
          autoFocus
          placeholder="Title"
          label="Title"
          value={this.state.title}
          onChange={e => this.setState({ title: e.target.value })}
        />
        <Form.TextArea
          id="postInputTextArea"
          style={{ fontFamily: 'Lato' }}
          placeholder="Text"
          label="Text"
          value={text}
          onChange={e => {
            const newText = e.target.value
            this.setState({
              text: newText,
              tagOpen:
                newText.length > text.length &&
                newText[newText.length - 1] === '@'
            })
          }}
        />
        <ImagesEditor
          inputId={inputId}
          hideButton
          images={images}
          onImages={images => this.setState({ images, r: Math.random() })}
        />
        <Button
          compact
          size="tiny"
          color={this.props.community.color}
          content="Submit"
        />
        <TagUserInput
          isOpen={tagOpen}
          onClose={() => {
            this.setState({ tagOpen: false })
            const el = document.getElementById('postInputTextArea')
            if (el) {
              el.focus()
            }
          }}
          onOpen={() => this.setState({ tagOpen: true })}
          onSelect={link => {
            const el = document.getElementById('postInputTextArea')
            if (el) {
              const startText = text.substring(0, el.selectionStart || 0)
              const endText = text.substring(el.selectionEnd || 0)
              this.setState({
                text: startText + link + endText
              })
            } else {
              this.setState({
                text: text + link
              })
            }
          }}
        />
        <UploadButton inputId={inputId} />
        <Button
          compact
          size="tiny"
          content="Cancel"
          type="button"
          onClick={this.props.close}
        />
        <FormattingHelp />
      </Form>
    )
  }
}

export default flow(withViewer, withModals)(PostForm)
