import React from 'react'
import { Message } from 'semantic-ui-react'
import { withModals } from '../contexts'

const MustLogIn = ({ modals, action = 'do that' }) => (
  <Message
    content={
      <span>
        {`In order to ${action} you must `}
        <span className="anchor" onClick={modals.logIn}>
          Log In
        </span>
        !
      </span>
    }
  />
)

export default withModals(MustLogIn)
