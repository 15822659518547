import firebase from 'firebase/app'
import 'firebase/messaging'
import api from './api'

const config = {
  apiKey: 'AIzaSyDZIA9cpy2DkunLT939l7S2_eT3Oq5VZyA',
  authDomain: 'forums-app-dev-1547324904040.firebaseapp.com',
  databaseURL: 'https://forums-app-dev-1547324904040.firebaseio.com',
  projectId: 'forums-app-dev-1547324904040',
  storageBucket: 'forums-app-dev-1547324904040.appspot.com',
  messagingSenderId: '320027026499',
  appId: '1:320027026499:web:f3457e48e7f65e0e3f20f6'
}

export function getAndSendToken() {
  if (!firebase.messaging.isSupported()) {
    return
  }

  getToken()
    .then(sendDeviceToken)
    .catch(e => console.error(e))
}

export async function getToken() {
  return firebase.messaging().getToken()
}

export function sendDeviceToken() {
  getToken()
    .then(deviceToken => {
      api
        .post('/profile/deviceTokens', {
          deviceToken
        })
        .then(() => {})
    })
    .catch(e => {
      console.error(e)
    })
}

firebase.initializeApp(config)

if (firebase.messaging.isSupported()) {
  const messaging = firebase.messaging()
  messaging.usePublicVapidKey(
    'BP3U9FfZeVHCPx99bXlvw32n3xD4aYVOsbuEpmSPhf_UWyDYRykshDx_RWxC9Th49R_XKzjlCCtVsAhNXLfdNQo'
  )

  messaging.onTokenRefresh(getAndSendToken)

  messaging.onMessage(function(payload) {
    const notificationTitle = payload.notification.title
    const notificationOptions = {
      body: payload.notification.body,
      icon: payload.notification.icon
    }

    // Let's check whether notification permissions have already been granted
    if ('Notification' in window && Notification.permission === 'granted') {
      // If it's okay let's create a notification
      const notification = new Notification(
        notificationTitle,
        notificationOptions
      )
      notification.onclick = function(event) {
        event.preventDefault() // prevent the browser from focusing the Notification's tab
        window.open(payload.notification.click_action, '_blank')
        notification.close()
      }
    }
  })
}
