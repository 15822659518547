import React, { Fragment } from 'react'
import withLoading from './hoc/withLoading'
import ErrorMessage from './errors/ErrorMessage'
import MyLoader from './MyLoader'
import PageTitle from './PageTitle'
import Post from './Post'
import Divider from 'semantic-ui-react/dist/es/elements/Divider/Divider'
import EmptyState from './EmptyState'
import api from '../utils/api'
import errorToast from '../utils/errorToast'

class AddSubPost extends React.Component {
  state = {}

  change = async (parentId, added) => {
    this.setState({ [parentId]: true })

    try {
      await api.post(`/posts/${parentId}/sub`, {
        postId: this.props.match.params.id,
        added
      })
      this.props.load()
    } catch (e) {
      errorToast(e)
    }
    this.setState({ [parentId]: false })
  }

  onAdd = async parentId => this.change(parentId, true)
  onRemove = async parentId => this.change(parentId, false)

  render() {
    const { data, loading, e } = this.props

    const parentIds = data
      ? data.post.parents.map(subPost => subPost.parentId)
      : []

    return (
      <Fragment>
        <ErrorMessage error={e} />
        {loading && <MyLoader />}
        {data && (
          <Fragment>
            <PageTitle title="Add To Parent" />
            <Post post={data.post} community={data.post.community} hideImages />
            <Divider />
            <h3>Parents</h3>
            {data.parents.length === 0 ? (
              <EmptyState />
            ) : (
              data.parents.map(parent => (
                <Post
                  key={parent.id}
                  post={parent}
                  community={parent.community}
                  hideImages
                  onAdd={
                    parentIds.includes(parent.id)
                      ? null
                      : () => this.onAdd(parent.id)
                  }
                  onRemove={
                    parentIds.includes(parent.id)
                      ? () => this.onRemove(parent.id)
                      : null
                  }
                  addingChild={this.state[parent.id]}
                />
              ))
            )}
          </Fragment>
        )}
      </Fragment>
    )
  }
}

export default withLoading(AddSubPost, { paginated: false })
