import React, { Fragment } from 'react'
import withLoading from './hoc/withLoading'
import ProfileAvatar from './ProfileAvatar'
import PageTitle from './PageTitle'
import { Divider, Form } from 'semantic-ui-react'
import PrimaryButton from './PrimaryButton'
import ErrorMessage from './errors/ErrorMessage'
import errorToast from '../utils/errorToast'
import api from '../utils/api'
import MyLoader from './MyLoader'

class StartThread extends React.Component {
  state = {
    title: '',
    text: '',
    sending: false
  }

  onSubmit = async e => {
    e.preventDefault()
    const { userId } = this.props.match.params
    const { title, text } = this.state

    this.setState({ sending: true })
    try {
      const { data } = await api.post('/messages', {
        userId,
        title,
        text
      })
      this.props.history.push(`/messages/${data.id}`)
    } catch (e) {
      errorToast(e)
      this.setState({ sending: false })
    }
  }

  render() {
    const { title, text, sending } = this.state
    const { data, loading, e } = this.props

    return (
      <div>
        <PageTitle title="New Message" />
        <ErrorMessage error={e} />
        {loading && <MyLoader />}
        {data && (
          <Fragment>
            <ProfileAvatar link user={data} large />
            <Divider />
            <Form onSubmit={this.onSubmit} loading={sending}>
              <Form.Input
                placeholder="Title"
                onChange={e => this.setState({ title: e.target.value })}
                value={title}
              />
              <Form.TextArea
                placeholder="Write a message..."
                onChange={e => this.setState({ text: e.target.value })}
                value={text}
              />
              <PrimaryButton content="Send" />
            </Form>
          </Fragment>
        )}
      </div>
    )
  }
}

export default withLoading(StartThread, {
  endpoint: props => `/users/${props.match.params.userId}`
})
