import React from 'react'
import api from '../utils/api'
import errorToast from '../utils/errorToast'
import ColoredButton from './ColoredButton'
import { Button, Form } from 'semantic-ui-react'
import ColorSelector from './selectors/ColorSelector'

export default class CreateCommunity extends React.PureComponent {
  state = {
    id: '',
    description: '',
    color: 'black',
    loading: false
  }

  create = async () => {
    const { id, description, color } = this.state

    this.setState({ loading: true })
    try {
      await api.post('/communities', { id, description, color })
      this.setState({
        id: '',
        description: '',
        loading: false
      })
      this.props.load()
    } catch (e) {
      this.setState({ loading: false })
      errorToast(e)
    }
  }

  render() {
    return (
      <Form loading={this.state.loading} onSubmit={this.create}>
        <Form.Group widths="equal">
          <Form.Input
            placeholder="ID"
            label="ID"
            value={this.state.id}
            onChange={e => this.setState({ id: e.target.value })}
          />
          <ColorSelector
            color={this.state.color}
            onSelect={color => this.setState({ color })}
          />
        </Form.Group>
        <Form.TextArea
          style={{ fontFamily: 'Lato' }}
          placeholder="Description"
          label="Description"
          value={this.state.description}
          onChange={e => this.setState({ description: e.target.value })}
        />
        <ColoredButton color="green" content="Create" />
        <Button content="Cancel" type="button" onClick={this.props.close} />
      </Form>
    )
  }
}
