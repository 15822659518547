import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Button, Divider, Form } from 'semantic-ui-react'
import { withRemoteConfig } from '../contexts'
import algoliaFromRemoteConfig from '../utils/algoliaFromRemoteConfig'
import errorToast from '../utils/errorToast'
import PageTitle from './PageTitle'
import ProfileAvatar from './ProfileAvatar'

class Find extends React.Component {
  state = {
    search: null,
    users: [],
    loading: false
  }

  search = async text => {
    this.setState({ loading: true })
    try {
      // const response = await api.post('/users/find', { search })
      // this.setState({
      //   loading: false,
      //   users: response.data
      // })
    } catch (e) {
      errorToast(e)
      this.setState({ loading: false })
    }
  }

  render() {
    const { users, loading } = this.state
    const { remoteConfig } = this.props

    const algolia = algoliaFromRemoteConfig(remoteConfig)

    return (
      <div>
        <PageTitle title="Find User" />
        <Form loading={loading} onSubmit={e => e.preventDefault()}>
          <Form.Input
            onChange={async e => {
              const text = e.target.value
              if (text.length < 3) {
                return this.setState({
                  users: []
                })
              }

              try {
                const { hits } = await algolia.users.search(text)
                this.setState({
                  users: hits.map(hit => {
                    return {
                      id: hit.objectID,
                      ...hit,
                      objectID: undefined
                    }
                  })
                })
              } catch (e) {
                errorToast(e)
              }
            }}
            placeholder="Name, Email"
          />
          <Button content="Search" />
        </Form>
        {users &&
          users.map(user => (
            <Fragment key={user.id}>
              <Divider />
              <Link to={`/messages/post/${user.id}`}>
                <ProfileAvatar large user={user} />
              </Link>
            </Fragment>
          ))}
      </div>
    )
  }
}

export default withRemoteConfig(Find)
