import LRU from 'lru-cache'

const maxAge = 1000 * 60 * 60 // one hour

const options = {
  max: 500,
  maxAge
}

const cache = new LRU(options)
export default cache

export function clearSensitiveCache() {
  cache.keys().forEach(key => {
    const parts = key.split('/')
    if (parts.includes('profile')) {
      cache.del(key)
    }
  })
}
