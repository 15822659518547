import React from 'react'
import { Button, Checkbox, Modal } from 'semantic-ui-react'
import { withModals } from '../contexts'

class TOSModal extends React.Component {
  state = {
    checked: false
  }

  render() {
    const { checked } = this.state
    const { onAccept } = this.props

    const setChecked = checked => this.setState({ checked })

    return (
      <Modal open className="modal-fix">
        <Modal.Content>
          <div className="m2">
            <h3 className="mb0">Welcome!</h3>
            <p>
              It appears you haven't accepted our license agreement on this
              device. Please read it and agree to continue.
            </p>
            <hr />
            <div style={{ maxHeight: 300, overflowY: 'scroll' }}>
              <h3>End­User License Agreement ("Agreement")</h3>
              Last updated: Jan 12, 2020 Please read this End­User License
              Agreement ("Agreement") carefully before clicking the "I Agree"
              button, downloading or using the Playing With Fire Forums or
              Application By clicking the "I Agree" button, downloading or using
              the Application, you are agreeing to be bound by the terms and
              conditions of this Agreement. If you do not agree to the terms of
              this Agreement, do not click on the "I Agree" button and do not
              download or use the Application.
              <h3>License</h3>
              Playing With Fire grants you a revocable, non­exclusive,
              non-­transferable, limited license to download, install and use
              the Application solely for your personal, non­commercial purposes
              strictly in accordance with the terms of this Agreement.
              <h3>Restrictions</h3>
              You agree not to, and you will not permit others to
              <ul>
                <li>
                  Engage in any kind of abusive behavior or post any kind of
                  objectionable content. There is no tolerance for abusive users
                </li>
                <li>
                  license, sell, rent, lease, assign, distribute, transmit,
                  host, outsource, disclose or otherwise commercially exploit
                  the Application or make the Application available to any third
                  party.
                </li>
              </ul>
              <h3>Modifications to Application</h3>
              Playing With Fire reserves the right to modify, suspend or
              discontinue, temporarily or permanently, the Application or any
              service to which it connects, with or without notice and without
              liability to you.
              <h3>Term and Termination</h3>
              This Agreement shall remain in effect until terminated by you or
              Playing With Fire. PWF may, in its sole discretion, at any time
              and for any or no reason, suspend or terminate this Agreement with
              or without prior notice. This Agreement will terminate
              immediately, without prior notice from Playing With Fire, in the
              event that you fail to comply with any provision of this
              Agreement. You may also terminate this Agreement by deleting the
              Application and all copies thereof from your mobile device or from
              your desktop. Upon termination of this Agreement, you shall cease
              all use of the Application and delete all copies of the
              Application from your mobile device or from your desktop.
              <h3>Severability</h3>
              If any provision of this Agreement is held to be unenforceable or
              invalid, such provision will be changed and interpreted to
              accomplish the objectives of such provision to the greatest extent
              possible under applicable law and the remaining provisions will
              continue in full force and effect.
              <h3>Amendments to this Agreement</h3>
              Playing With Fire reserves the right, at its sole discretion, to
              modify or replace this Agreement at any time. If a revision is
              material we will provide at least 3​0 days notice prior to any new
              terms taking effect. What constitutes a material change will be
              determined at our sole discretion.
              <h3>Contact Information</h3>
              If you have any questions about this Agreement, please contact us
              at{' '}
              <a href="https://www.playingfire.com/contact">
                https://www.playingfire.com/contact
              </a>
            </div>
            <hr />
            <Checkbox
              label="I Agree"
              checked={checked}
              onChange={(e, { checked }) => setChecked(checked)}
            />
            <div className="mt1" />
            <Button
              disabled={!checked}
              color="blue"
              className="mt1"
              fluid
              onClick={onAccept}
              content="Save"
            />
          </div>
        </Modal.Content>
      </Modal>
    )
  }
}

export default withModals(TOSModal)
