import React, { Fragment } from 'react'
import CommunitySubCount from './CommunitySubCount'
import CommunitySince from './CommunitySince'
import EmptyState from './EmptyState'
import ProfileAvatar from './ProfileAvatar'
import { withViewer } from '../contexts'
import api from '../utils/api'
import errorToast from '../utils/errorToast'
import {
  Button,
  Checkbox,
  Divider,
  Form,
  FormInput,
  Header,
  Modal,
  ModalContent,
  Segment
} from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'

class CommunityHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showModerators: false,
      moderatorsLoading: false,
      privateChecked: !!props.community.isPrivate,
      deleting: false,
      showDeleteModal: false,
      deleteModalText: ''
    }
  }

  toggleDeleteModal = () =>
    this.setState({ showDeleteModal: !this.state.showDeleteModal })

  delete = async () => {
    this.setState({ deleting: true, showDeleteModal: false })
    try {
      await api.delete(`/communities/${this.props.community.id}`)
      this.props.history.push('/')
    } catch (e) {
      errorToast(e)
      this.setState({ deleting: false })
    }
  }

  removeModerator = async id => {
    this.setState({ moderatorsLoading: true })
    try {
      await api.delete(`/moderators/${id}`)
      this.props.load()
    } catch (e) {
      errorToast(e)
    } finally {
      this.setState({ moderatorsLoading: false })
    }
  }

  toggleShowModerators = () =>
    this.setState({ showModerators: !this.state.showModerators })

  onPrivateCheckChange = (e, { checked: isPrivate }) => {
    this.setState({ privateChecked: isPrivate })
    return api
      .put(`/communities/${this.props.community.id}`, { isPrivate })
      .then(this.props.load)
      .catch(errorToast)
  }

  render() {
    const {
      showModerators,
      moderatorsLoading,
      deleting,
      showDeleteModal,
      deleteModalText
    } = this.state
    const { community, large, allowEdits, viewer, admin, inverted } = this.props
    const { moderators } = community

    const isMine = viewer && viewer.id === community.userId

    const displayName = community.displayName || community.id

    return (
      <Fragment>
        <div
          className="black flex justify-between items-center"
          style={{
            backgroundColor: inverted ? '#191919' : undefined,
            borderRadius: inverted ? 4 : undefined,
            padding: inverted ? 12 : undefined
          }}>
          <div>
            <div className="flex items-center">
              {large && inverted && (
                <img
                  className="mr1"
                  height={25}
                  width={25}
                  src="https://www.playingfire.com/wp-content/uploads/2017/04/cropped-favicon2-300x300.png"
                  alt="Community"
                />
              )}
              {large && (
                <Header
                  inverted={inverted}
                  as={inverted ? 'h2' : 'h1'}
                  style={{
                    margin: 0,
                    fontFamily: inverted ? 'special' : undefined
                  }}>
                  {displayName}
                </Header>
              )}
              {!large && (
                <Header inverted={inverted} className="h3">
                  {displayName}
                </Header>
              )}
            </div>
            <span>
              <CommunitySince inverted={inverted} community={community} />
              {allowEdits && (
                <span className="text-small no-select">
                  {' | '}
                  <span className="anchor" onClick={this.toggleShowModerators}>
                    {showModerators ? 'Hide' : 'Show'} Mods
                  </span>
                </span>
              )}
            </span>
          </div>
          <CommunitySubCount
            inverted={inverted}
            size="tiny"
            community={community}
          />
        </div>
        {showModerators &&
          (moderators.length > 0 ? (
            <Segment loading={moderatorsLoading}>
              {moderators.map((moderator, i) => (
                <Fragment key={moderator.id}>
                  <ProfileAvatar user={moderator.user} link />
                  {(isMine || admin) && (
                    <span
                      className="anchor"
                      onClick={() => this.removeModerator(moderator.id)}>
                      Remove
                    </span>
                  )}
                  {i < moderators.length - 1 && <Divider />}
                </Fragment>
              ))}
            </Segment>
          ) : (
            <EmptyState />
          ))}
        {allowEdits && admin && (
          <div className="flex flex-wrap items-center">
            <Checkbox
              label="Private"
              checked={this.state.privateChecked}
              onChange={this.onPrivateCheckChange}
            />
            <div className="ml1" />
            <Button
              basic
              size="small"
              compact
              loading={deleting}
              color={community.color}
              content="Delete"
              onClick={this.toggleDeleteModal}
            />
          </div>
        )}
        <Modal
          onConfirm={this.delete}
          onCancel={this.toggleDeleteModal}
          open={showDeleteModal}
          confirmButton="Delete">
          <ModalContent>
            <Form onSubmit={this.delete}>
              <p>
                This action is non-reversible! Please type DELETE to confirm.
              </p>
              <FormInput
                onChange={e =>
                  this.setState({ deleteModalText: e.target.value })
                }
                value={deleteModalText}
              />
              <Button
                disabled={deleteModalText !== 'DELETE'}
                negative
                content="Delete"
              />
            </Form>
          </ModalContent>
        </Modal>
      </Fragment>
    )
  }
}

export default withViewer(withRouter(CommunityHeader))
