import React from 'react'
import PropTypes from 'prop-types'
import { Message } from 'semantic-ui-react'
import NotFound from './NotFound'
import errorString from '../../utils/errorString'

const ErrorMessage = ({ error }) => {
  if (!error) return null
  if (error === 404) return <NotFound />
  const errorMessage = typeof error === 'string' ? error : errorString(error)
  return <Message negative>{errorMessage}</Message>
}

ErrorMessage.propTypes = {
  error: PropTypes.any
}

export default ErrorMessage
