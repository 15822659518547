import React from 'react'
import { Divider } from 'semantic-ui-react'
import PageTitle from './PageTitle'

const changelog = [
  {
    version: '1.1.13',
    changes: ['Added an About page']
  },
  {
    version: '1.1.12',
    changes: [
      'Full revamp of user and post search',
      'Removed log in requirement to view comments and images'
    ]
  }
]

export default () => {
  return (
    <div>
      <PageTitle title="About" />
      <p>
        Welcome to the PWF Forums. We created this space as a way for us to have
        free and open communication in the PWF community without our content
        being curated by big tech companies.
      </p>
      <Divider />
      <h3>Changelog</h3>
      <p>
        Here you will find our most recent improvements to the forums website:
      </p>
      {changelog.map((item, index) => {
        return (
          <div key={index.toString()}>
            <h4>{item.version}</h4>
            <ul>
              {item.changes.map((change, index) => {
                return <li key={index.toString()}>{change}</li>
              })}
            </ul>
          </div>
        )
      })}
    </div>
  )
}
