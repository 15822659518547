import React from 'react'
import PropTypes from 'prop-types'
import api from '../utils/api'
import errorToast from '../utils/errorToast'
import Dimmer from './Dimmer'

export const UploadButton = ({ inputId }) => (
  <label htmlFor={inputId} className={'ui compact tiny button pointer'}>
    Upload Images
  </label>
)

const ImageControl = ({ icon, onClick }) => (
  <div className="col-4 center pointer hover-gray py1" onClick={onClick}>
    <i className="material-icons small-icon no-select">{icon}</i>
  </div>
)

export default class ImagesEditor extends React.Component {
  state = {
    loading: false
  }

  uploadImage = async file => {
    const data = new FormData()
    data.append('file', file)

    const response = await api.post('/images', data)
    return response.data
  }

  uploadImages = async e => {
    const { files } = e.target

    if (!files || files.length === 0) {
      return
    }

    this.setLoading(true)
    try {
      const uploadedImages = await Promise.all([...files].map(this.uploadImage))

      const { images, onImages } = this.props

      onImages([...images, ...uploadedImages])
    } catch (e) {
      errorToast(e)
    } finally {
      this.setLoading(false)
    }
  }

  setLoading = loading => this.setState({ loading })

  render() {
    const { images, inputId } = this.props

    return (
      <div>
        {this.state.loading && <Dimmer />}
        <input
          multiple
          hidden
          type="file"
          name={inputId}
          id={inputId}
          accept={'image/*'}
          onChange={this.uploadImages}
        />

        {images.length > 0 && (
          <div className="flex flex-wrap my2">
            {images.length > 0 &&
              images.map(({ id, thumbnail }, i) => (
                <div className="mr1" key={`image-${i}`}>
                  <div
                    className="flex justify-center"
                    style={{
                      height: 100,
                      width: '100%',
                      backgroundColor: 'black'
                    }}>
                    <img
                      style={{ width: 100, height: 100, overflow: 'hidden' }}
                      alt="Post/Comment"
                      className="object-fit-contain"
                      src={thumbnail}
                    />
                  </div>
                  <div className="flex" style={{ backgroundColor: '#e0e1e2' }}>
                    <ImageControl
                      icon="keyboard_arrow_left"
                      onClick={() => {
                        if (i < 1 || images.length < 2) {
                          return
                        }

                        const copy = images[i]
                        images[i] = images[i - 1]
                        images[i - 1] = copy

                        this.props.onImages(images)
                      }}
                    />
                    <ImageControl
                      icon="delete"
                      onClick={() => {
                        images.splice(i, 1)
                        this.props.onImages(images)
                      }}
                    />
                    <ImageControl
                      icon="keyboard_arrow_right"
                      onClick={() => {
                        if (i >= images.length - 1 || images.length < 2) {
                          return
                        }

                        const copy = images[i]
                        images[i] = images[i + 1]
                        images[i + 1] = copy

                        this.props.onImages(images)
                      }}
                    />
                  </div>
                  {/*<div className="absolute top-0 left-0 p1">*/}
                  {/*<div className="flex card-item background-white">*/}
                  {/*<div*/}
                  {/*className="p1 pointer hover-gray"*/}
                  {/*onClick={async () => {*/}
                  {/*images.splice(i, 1)*/}
                  {/*this.props.onImages(images)*/}
                  {/*}}>*/}
                  {/*<Icon*/}
                  {/*className="p0 m0"*/}
                  {/*style={{ margin: 0, padding: 0 }}*/}
                  {/*name="trash"*/}
                  {/*size="small"*/}
                  {/*/>*/}
                  {/*</div>*/}
                  {/*</div>*/}
                  {/*</div>*/}
                </div>
              ))}
          </div>
        )}
      </div>
    )
  }
}

ImagesEditor.propTypes = {
  images: PropTypes.array.isRequired,
  onImages: PropTypes.func.isRequired
}
