import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Form, Segment } from 'semantic-ui-react'
import { withViewer } from '../contexts'
import api from '../utils/api'
import errorToast from '../utils/errorToast'
import ErrorMessage from './errors/ErrorMessage'
import FromNow from './FromNow'
import withLoading from './hoc/withLoading'
import MarkdownParagraph from './MarkdownParagraph'
import MyLoader from './MyLoader'
import PageTitle from './PageTitle'
import PrimaryButton from './PrimaryButton'
import ProfileAvatar from './ProfileAvatar'

const Message = ({ message }) => {
  return (
    <Segment>
      <ProfileAvatar link user={message.user} />
      <FromNow date={message.createdAt} />
      <MarkdownParagraph startExpanded text={message.text} />
    </Segment>
  )
}

class SendForm extends React.Component {
  state = {
    text: '',
    sending: false
  }

  onSubmit = async e => {
    e.preventDefault()

    const { text } = this.state
    const { threadId } = this.props

    this.setState({ sending: true })
    try {
      await api.post(`/messages/${threadId}/message`, {
        text
      })
      this.props.load()
      this.setState({ text: '', sending: false })
    } catch (e) {
      errorToast(e)
      this.setState({ sending: false })
    }
  }

  render() {
    const { text, sending } = this.state

    return (
      <Form loading={sending} onSubmit={this.onSubmit}>
        <Form.TextArea
          placeholder="Write a message..."
          value={text}
          onChange={e => this.setState({ text: e.target.value })}
        />
        <PrimaryButton content="Send" disabled={!text} />
      </Form>
    )
  }
}

class PageContent extends React.Component {
  render() {
    const { data, load, viewer } = this.props
    const { thread, rows } = data

    const users = [thread.user1, thread.user2]
    const otherUser = users.find(user => user.id !== viewer.id) || thread.user2

    return (
      <Fragment>
        <PageTitle hidden title="Thread" />
        <h3>{thread.title}</h3>
        <span>
          Message thread with{' '}
          <Link to={`/users/${otherUser.id}`}>
            <b>{otherUser.firstName + ' ' + otherUser.lastName}</b>
          </Link>
        </span>
        <div className="mt1" />
        <SendForm threadId={thread.id} load={load} />
        {rows.map(message => (
          <Message key={message.id} message={message} />
        ))}
      </Fragment>
    )
  }
}

const PageContentV = withViewer(PageContent)

class ThreadPage extends React.Component {
  render() {
    const { data, loading, e, load } = this.props

    return (
      <div>
        <ErrorMessage error={e} />
        {loading && <MyLoader />}
        {loading && <PageTitle hidden title="Loading..." />}
        {data && <PageContentV data={data} load={load} />}
      </div>
    )
  }
}

export default withLoading(ThreadPage, {
  paginated: true
})
