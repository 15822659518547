export default function(value) {
  if (value < 1000) {
    return value + ''
  }

  const suffixes = ['', 'k', 'm', 'b', 't']
  const suffixNum = Math.floor(('' + value).length / 3)
  const shortValue = parseFloat(
    (suffixNum !== 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(2)
  )
  return shortValue + suffixes[suffixNum]
}
