import React, { Fragment } from 'react'
import { Message } from 'semantic-ui-react'
import { toast } from 'react-toastify'
import api from '../utils/api'
import errorToast from '../utils/errorToast'
import Dimmer from './Dimmer'

class Unverified extends React.Component {
  state = {
    loading: false
  }

  resend = async () => {
    this.setState({ loading: true })
    try {
      await api.post('/auth/verify/resend')
      toast.success('Verification email sent!')
    } catch (e) {
      console.error(e)
      errorToast(e)
    } finally {
      this.setState({ loading: false })
    }
  }

  render() {
    return (
      <Fragment>
        {this.state.loading && <Dimmer />}
        <Message
          warning
          header="Your account is unverified!"
          content={
            <span className="anchor" onClick={this.resend}>
              Click here to resend a verification email.
            </span>
          }
        />
      </Fragment>
    )
  }
}

export default Unverified
