import React, { Fragment } from 'react'
import { Pagination } from 'semantic-ui-react'
import cache from '../../utils/cache'
import { withRouter } from 'react-router-dom'
import qs from 'qs'
import api from '../../utils/api'
import { isEqual } from 'lodash'
import PrivateCommunity from '../PrivateCommunity'

export function getQuery(props) {
  let { search } = props.location
  if (!search || search.length < 2) {
    return {}
  }

  search = search.substring(1)
  return qs.parse(search)
}

export default (
  Component,
  {
    endpoint,
    paginated,
    tryhard = true,
    logging = true,
    removeQuery,
    hidePaginationOnSinglePage
  }
) =>
  withRouter(
    class extends React.Component {
      log = (...s) => logging && console.log(...s)

      constructor(props) {
        super(props)
        const cached = cache.get(this.endpointWithQuery())

        if (cached) {
          this.log('[CACHE]', this.endpointWithQuery(), cached)
        }

        this.state = {
          loading: !cached,
          data: cached || null,
          e: null
        }
      }

      componentDidMount() {
        if (!this.state.data || tryhard) {
          this.load()
        }
      }

      getEndpoint = () => {
        if (!endpoint) {
          return this.props.location.pathname
        }

        if (typeof endpoint === 'function') {
          return endpoint(this.props)
        }

        return endpoint
      }

      getPage = () => {
        const page = parseInt(getQuery(this.props).page, 10)

        if (isNaN(page) || !page || page < 1) {
          return 1
        }

        return page
      }

      endpointWithQuery = () => {
        const query = getQuery(this.props)
        if (query.page === '1') {
          delete query.page
        }

        let result = this.getEndpoint()
        if (!removeQuery && Object.keys(query).length > 0) {
          result += '?' + qs.stringify(query)
        }

        return result
      }

      componentDidUpdate(prevProps) {
        const query = getQuery(this.props)
        const prevQuery = getQuery(prevProps)
        if (!isEqual(query, prevQuery)) {
          this.loadWithCache()
        }
      }

      onData = (data, fromCache) => {
        const first = fromCache ? '[CACHE]' : '[API]'
        this.log(first, this.endpointWithQuery(), data)
        this.setState({ loading: false, data, e: null })

        if (!fromCache) {
          cache.set(this.endpointWithQuery(), data)
        }
      }

      loadWithCache = () => {
        this.loadFromCache()
        this.load()
      }

      loadFromCache = () => {
        const cached = cache.get(this.endpointWithQuery())
        if (cached) {
          this.onData(cached, true)
        } else {
          this.setState({ loading: true })
        }
      }

      load = async () => {
        const params = getQuery(this.props)
        params.page = this.getPage()
        try {
          const { data } = await api.get(this.getEndpoint(), { params })
          this.onData(data)
        } catch (e) {
          console.error(this.endpointWithQuery(), e)
          this.setState({ loading: false, e })
        }
      }

      render() {
        let { loading, data, e } = this.state

        let pages
        if (data && data.hasOwnProperty('count')) {
          pages = Math.ceil(data.count / data.limit)
        }

        if (e && e.response.status === 491) {
          return <PrivateCommunity />
        }

        return (
          <Fragment>
            <Component
              {...this.props}
              loading={loading}
              data={!loading ? data : null}
              e={e}
              onData={this.onData}
              load={this.load}
              rows={!loading && data ? data.rows : null}
            />
            {!loading &&
              data &&
              paginated &&
              pages > 0 &&
              !(pages === 1 && hidePaginationOnSinglePage) && (
                <div className="mt1">
                  <Pagination
                    firstItem={null}
                    lastItem={null}
                    pointing
                    secondary
                    boundaryRange={1}
                    siblingRange={0}
                    totalPages={pages}
                    onPageChange={(e, { activePage }) => {
                      const query = getQuery(this.props)
                      query.page = activePage
                      this.props.history.push(
                        `${this.props.location.pathname}?${qs.stringify(query)}`
                      )
                    }}
                    activePage={this.getPage()}
                  />
                </div>
              )}
          </Fragment>
        )
      }
    }
  )
