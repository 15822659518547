import React from 'react'
import { Container } from 'semantic-ui-react'
import { isWebView } from '../utils/mobileUserAgent'

export default ({ children }) => (
  <Container className="my2">
    <div
      className="sidebar-only-margin-x"
      style={{
        paddingBottom: isWebView() ? 60 : undefined
      }}>
      {children}
    </div>
  </Container>
)
