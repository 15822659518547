import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'semantic-ui-react'
import PrimaryButton from './PrimaryButton'

const Editable = ({
  editing,
  normalView,
  editingView,
  editable,
  onSave,
  toggle,
  hide,
  show,
  showCancel = true,
  showEditButton = true
}) => (
  <Fragment>
    {editing ? editingView : normalView}
    {editable && (
      <div className="my1">
        {editing ? (
          <Fragment>
            <PrimaryButton
              content="Save"
              size="mini"
              compact
              onClick={onSave}
            />
            {showCancel && (
              <Button
                content="Cancel"
                size="mini"
                compact
                onClick={() => (hide ? hide() : toggle())}
              />
            )}
          </Fragment>
        ) : showEditButton ? (
          <Button
            size="mini"
            compact
            basic
            onClick={() => (show ? show() : toggle())}
            content="Edit"
          />
        ) : null}
      </div>
    )}
  </Fragment>
)

Editable.propTypes = {
  normalView: PropTypes.node.isRequired,
  editingView: PropTypes.node,
  editable: PropTypes.bool.isRequired,
  onSave: PropTypes.func,
  editing: PropTypes.bool,
  toggle: PropTypes.func,
  hide: PropTypes.func,
  show: PropTypes.func
}

export default Editable
