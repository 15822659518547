import React from 'react'
import ReactMarkdown from 'react-markdown'
import breaks from 'remark-breaks'

class MarkdownParagraph extends React.Component {
  state = {
    expanded: false
  }

  render() {
    const { text, startExpanded } = this.props

    if (!text || text.length === 0) {
      return null
    }

    const { expanded } = this.state
    const classes = ['my1']

    if (!startExpanded && !expanded) {
      classes.push('markdown-container')
    }

    return (
      <div
        onClick={expanded ? null : () => this.setState({ expanded: true })}
        className={classes.join(' ')}
        style={{
          wordWrap: 'break-word',
          whiteSpace: 'normal',
          maxHeight: expanded ? null : 100
        }}>
        <ReactMarkdown source={this.props.text} plugins={[breaks]} />
      </div>
    )
  }
}

export default MarkdownParagraph
