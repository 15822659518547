import PageTitle from './PageTitle'
import React, { useContext } from 'react'
import { RemoteConfigContext } from '../contexts'
import MarkdownParagraph from './MarkdownParagraph'

export default () => {
  const { remoteConfig: config } = useContext(RemoteConfigContext)

  return (
    <div>
      <PageTitle title="Private Group" hidden />
      {config && config.privateGroupMarkdown && (
        <MarkdownParagraph text={config.privateGroupMarkdown} startExpanded />
      )}
    </div>
  )
}
