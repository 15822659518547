import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import profilePicture from '../utils/profilePicture'
import { Image } from 'semantic-ui-react'
import Lightbox from 'react-image-lightbox'

class AvatarPicture extends React.Component {
  state = {
    isOpen: false
  }

  toggleLightbox = e => {
    e && e.preventDefault()
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    const { large, user, allowExpandImage } = this.props
    const { firstName, lastName } = user
    const { isOpen } = this.state

    let imgClass = large ? ['profile-picture-large'] : ['profile-picture']

    const src = profilePicture(user)
    if (allowExpandImage && src) {
      imgClass.push('pointer')
    }

    let initials = ''
    if (firstName) {
      initials += firstName[0]
    }
    if (lastName) {
      initials += lastName[0]
    }

    if (initials === '') {
      initials = '?'
    }

    initials = initials.toUpperCase()

    return src ? (
      <Fragment>
        <Image
          className={imgClass.join(' ')}
          rounded
          src={src}
          onClick={allowExpandImage ? this.toggleLightbox : null}
        />
        {isOpen && allowExpandImage && (
          <Lightbox
            mainSrc={profilePicture(user, true)}
            onCloseRequest={this.toggleLightbox}
          />
        )}
      </Fragment>
    ) : (
      <div
        className={`flex justify-center ${imgClass} white rounded items-center bg-colorPrimary`}
      >
        <span className={large ? 'h2' : 'h4'}>{initials}</span>
      </div>
    )
  }
}

AvatarPicture.propTypes = {
  large: PropTypes.bool,
  user: PropTypes.object.isRequired
}

export default AvatarPicture
