import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  Button,
  Checkbox,
  Confirm,
  Divider,
  Form,
  FormInput,
  FormTextArea,
  Icon,
  Label,
  Popup,
  Segment
} from 'semantic-ui-react'
import { withViewer } from '../contexts'
import api from '../utils/api'
import errorToast from '../utils/errorToast'
import isModerator from '../utils/isModerator'
import plural from '../utils/plural'
import uploadSound from '../utils/uploadSound'
import CommentInput from './CommentInput'
import Editable from './Editable'
import FlagButton from './FlagButton'
import FromNow from './FromNow'
import Images from './Images'
import MarkdownParagraph from './MarkdownParagraph'
import More from './More'
import UserAnchor from './UserAnchor'
import VoteControls from './VoteControls'

class Post extends React.PureComponent {
  constructor(props) {
    super(props)

    const { post } = this.props

    this.state = {
      text: post.text,
      showComment: false,
      commenting: false,
      upvoting: false,
      downvoting: false,
      images: [],
      deleting: false,
      confirmDelete: false,
      editing: false,
      input: post.text,
      expanded: false,
      title: post.title,
      editingTitle: false,
      parentChecked: !!post.isParent,
      highValueChecked: !!post.highValue,
      reportModalOpen: false,
      reportModalLoading: false
    }
  }

  onParentCheckChange = async (e, { checked: isParent }) => {
    this.setState({ parentChecked: isParent })
    return this.edit({ isParent })
  }

  onHighValueChange = async (e, { checked: highValue }) => {
    this.setState({ highValueChecked: highValue })
    return this.edit({ highValue })
  }

  toggleComment = () => this.setState({ showComment: !this.state.showComment })

  comment = async ({ text, blobURL }) => {
    const { images } = this.state

    this.setState({ commenting: true })
    try {
      let soundURL
      if (blobURL) {
        soundURL = await uploadSound(blobURL)
      }
      await api.post(`/posts/${this.props.post.id}/comment`, {
        text,
        images,
        soundURL
      })
      this.props.load()
      this.setState({
        text: '',
        images: [],
        commenting: false,
        showComment: false
      })
    } catch (e) {
      errorToast(e)
      this.setState({
        commenting: false
      })
    }
  }

  downvote = async () => {
    this.setState({ downvoting: true })
    try {
      await api.post(`/posts/${this.props.post.id}/downvote`)
      // this.props.load()
    } catch (e) {
      errorToast(e)
    } finally {
      this.setState({ downvoting: false })
    }
  }

  upvote = async () => {
    this.setState({ upvoting: true })
    try {
      await api.post(`/posts/${this.props.post.id}/upvote`)
      // this.props.load()
    } catch (e) {
      errorToast(e)
    } finally {
      this.setState({ upvoting: false })
    }
  }

  delete = async () => {
    this.setState({
      confirmDelete: false,
      deleting: true
    })

    try {
      await api.delete(`/posts/${this.props.post.id}`)
      this.props.load()
    } catch (e) {
      errorToast(e)
    } finally {
      this.setState({ deleting: false })
    }
  }

  editTitle = () => this.edit({ title: this.state.title })

  editText = () => this.edit({ text: this.state.input })

  edit = async update => {
    this.setState({
      deleting: true
    })

    try {
      await api.put(`/posts/${this.props.post.id}`, update)
      this.props.load()
      this.setState({
        deleting: false,
        editing: false,
        editingTitle: false
      })
    } catch (e) {
      this.setState({ deleting: false })
      errorToast(e)
    }
  }

  onPinClicked = async () => {
    this.setState({
      deleting: true
    })

    try {
      await api.put(`/posts/${this.props.post.id}`, {
        pinned: !this.props.post.pinned
      })
      this.props.load()
    } catch (e) {
      errorToast(e)
    } finally {
      this.setState({ deleting: false })
    }
  }

  toggleConfirmDelete = () =>
    this.setState({ confirmDelete: !this.state.confirmDelete })
  toggleEditing = () => this.setState({ editing: !this.state.editing })
  toggleEditingTitle = () =>
    this.setState({ editingTitle: !this.state.editingTitle })

  updateSubPostOrder = async arr => {
    const items = arr.map((item, i) => {
      return {
        id: item.id,
        order: i
      }
    })

    this.setState({ deleting: true })
    try {
      await api.put('/subPosts', items)
      this.props.load()
    } catch (e) {
      errorToast(e)
    } finally {
      this.setState({ deleting: false })
    }
  }

  render() {
    // TODO handle addingChild/deleting (old loading state)
    // addingChild is prop
    // deleting is state

    const {
      community,
      post,
      showCommunity,
      viewer,
      allowControls,
      hideImages,
      onAdd,
      onRemove,
      hideActions,
      allowReordering,
      onUp,
      onDown,
      hideText,
      noEditTitle,
      hideVoteControls,
      hideLabels,
      greenSegment
    } = this.props
    const { images, pinned, isParent } = post
    const {
      confirmDelete,
      editing,
      input,
      title,
      editingTitle,
      parentChecked,
      highValueChecked,
      reportModalLoading,
      reportModalOpen
    } = this.state

    const isMine = viewer && viewer.id === post.userId
    const deleted = post.text === '*deleted*'
    const mod = isModerator(viewer, community)
    const deletable = !deleted && (isMine || mod)
    const editable = !deleted && isMine
    const premium = viewer && viewer.premium

    const content = (
      <Fragment>
        <div className="flex">
          {!hideVoteControls && (
            <VoteControls
              community={community}
              item={post}
              upvote={this.upvote}
              downvote={this.downvote}
              seeVotesUrl={`/posts/${post.id}/votes`}
            />
          )}
          <div id="everything-to-right-of-vote" className="flex flex-auto">
            <div id="left" className="flex-auto">
              <Editable
                editable={!noEditTitle && isModerator(viewer, community)}
                editing={editingTitle}
                normalView={
                  <Link to={`/posts/${post.id}`} className="mt1">
                    <h3 className="m0 black hover-underline">{post.title}</h3>
                  </Link>
                }
                editingView={
                  <Form className="mt1" onSubmit={e => e.preventDefault()}>
                    <FormInput
                      value={title}
                      onChange={e => this.setState({ title: e.target.value })}
                    />
                  </Form>
                }
                onSave={this.editTitle}
                toggle={this.toggleEditingTitle}
              />
              <div className="flex items-center flex-wrap">
                <div className="flex items-center posted-by-push-right">
                  <span className="text-small text-dim posted-by-push-right">
                    Posted
                  </span>
                  <FromNow date={post.createdAt} edited={post.editedAt} />
                </div>
                {showCommunity && (
                  <span className="text-small text-dim posted-by-push-right">
                    in
                    <span className="posted-by-push-right" />
                    <Link to={`/communities/${community.id}`}>
                      <span className="hover-underline">
                        {community.displayName || community.id}
                      </span>
                    </Link>
                  </span>
                )}
                {post.user && (
                  <div className="flex items-center flex-wrap">
                    <span className="text-small text-dim posted-by-push-right">
                      by
                    </span>
                    <UserAnchor user={post.user} />
                  </div>
                )}
              </div>
              {!hideText && (
                <Editable
                  normalView={
                    <MarkdownParagraph
                      text={
                        community.isPrivate && !premium
                          ? 'Content removed because it was posted in a premium community.'
                          : post.text
                      }
                    />
                  }
                  editable={editable || false}
                  editing={editing}
                  toggle={this.toggleEditing}
                  onSave={this.editText}
                  editingView={
                    <Form onSubmit={e => e.preventDefault()}>
                      <FormTextArea
                        value={input}
                        onChange={e => this.setState({ input: e.target.value })}
                      />
                    </Form>
                  }
                />
              )}
            </div>
            {!hideLabels && (
              <div id="right">
                {isParent && <Label color={community.color}>Lesson</Label>}
                {(pinned || mod) && (
                  <Popup
                    content={mod ? (pinned ? 'Unpin' : 'Pin') : 'Pinned'}
                    trigger={
                      <Icon
                        onClick={mod && this.onPinClicked}
                        className={mod ? 'pointer' : ''}
                        color={pinned ? community.color : 'grey'}
                        name="pin"
                      />
                    }
                  />
                )}
              </div>
            )}
          </div>
        </div>
        {allowControls && mod && (
          <Segment>
            <Checkbox
              className="mr1"
              checked={highValueChecked}
              onChange={this.onHighValueChange}
              label="High Value"
            />
            <Checkbox
              className="mr1"
              checked={parentChecked}
              onChange={this.onParentCheckChange}
              label="Parent Post"
            />
            <Link to={`/posts/${post.id}/add`}>
              <Button size="tiny" compact content="Attach To Parent" />
            </Link>
          </Segment>
        )}
        {!hideImages && <Images images={images} />}
        {post.subPosts && post.subPosts.length > 0 && (
          <Fragment>
            <Divider />
            <h4>Subposts</h4>
            {post.subPosts.map((subPost, i) => {
              return (
                <Post
                  key={subPost.id}
                  post={subPost.post}
                  community={subPost.post.community}
                  hideImages
                  hideActions
                  allowReordering
                  onUp={
                    i > 0
                      ? () => {
                          const newArr = [...post.subPosts]
                          const tempSubPost = newArr[i]
                          newArr[i] = newArr[i - 1]
                          newArr[i - 1] = tempSubPost
                          this.updateSubPostOrder(newArr)
                        }
                      : null
                  }
                  onDown={
                    i < post.subPosts.length - 1
                      ? () => {
                          const newArr = [...post.subPosts]
                          const tempSubPost = newArr[i]
                          newArr[i] = newArr[i + 1]
                          newArr[i + 1] = tempSubPost
                          this.updateSubPostOrder(newArr)
                        }
                      : null
                  }
                />
              )
            })}
          </Fragment>
        )}
        {onAdd && (
          <Fragment>
            <Divider />
            <Button
              size="tiny"
              compact
              content="Attach"
              color="green"
              onClick={onAdd}
            />
          </Fragment>
        )}
        {onRemove && (
          <Fragment>
            <Divider />
            <Button
              size="tiny"
              compact
              content="Remove"
              color="red"
              onClick={onRemove}
            />
          </Fragment>
        )}
        {allowReordering && (
          <Fragment>
            <Divider />
            <Button
              size="tiny"
              compact
              content="Up"
              disabled={!onUp}
              onClick={onUp}
            />
            <Button
              size="tiny"
              compact
              content="Down"
              disabled={!onDown}
              onClick={onDown}
            />
          </Fragment>
        )}
        {!hideActions && post.id !== 'deleted-post' && (
          <div className="flex flex-wrap" style={{ marginTop: 4 }}>
            <Button
              size="tiny"
              compact
              disabled={this.state.showComment}
              content="Comment"
              color={community.color}
              onClick={this.toggleComment}
            />
            <Button
              as={Link}
              to={`/posts/${post.id}`}
              size="tiny"
              compact
              basic
              content={plural(post.commentCount, 'Comment', 'Comments')}
            />
            <Button
              as={Link}
              to={`/posts/${post.id}/votes`}
              size="tiny"
              compact
              basic
              content="See votes"
            />
            <FlagButton
              open={reportModalOpen}
              toggle={() =>
                this.setState({ reportModalOpen: !reportModalOpen })
              }
              loading={reportModalLoading}
              onSubmit={async text => {
                this.setState({ reportModalLoading: true })
                try {
                  await api.post('/reports', {
                    postId: post.id,
                    text
                  })
                  toast.success('Content flagged successfully. Thank you!')
                  this.setState({
                    reportModalOpen: false,
                    reportModalLoading: false
                  })
                } catch (e) {
                  errorToast(e)
                  this.setState({ reportModalLoading: false })
                }
              }}
            />
            <More onDelete={deletable && this.toggleConfirmDelete} />
          </div>
        )}
        {this.state.showComment && (
          <CommentInput
            loading={this.state.commenting}
            onSubmit={this.comment}
            community={community}
            close={this.toggleComment}
            placeholder="Type your comment..."
            inputId={post.id}
            images={this.state.images}
            onImages={images => this.setState({ images, r: Math.random() })}
          />
        )}
        <Confirm
          open={confirmDelete}
          onCancel={this.toggleConfirmDelete}
          onConfirm={this.delete}
        />
      </Fragment>
    )

    return greenSegment ? (
      <Segment color="green">{content}</Segment>
    ) : (
      <div className="my1">{content}</div>
    )
  }
}

export default withViewer(Post)
