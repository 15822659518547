import { flow } from 'lodash'
import React from 'react'
import { Button, Form, FormTextArea } from 'semantic-ui-react'
import { withModals, withViewer } from '../contexts'
import FormattingHelp from './FormattingHelp'
import ImagesEditor, { UploadButton } from './ImagesEditor'
import TagUserInput from './TagUserInput'

class CommentInput extends React.Component {
  state = {
    text: '',
    recording: false,
    blobURL: undefined,
    doneRecording: false,
    recorder: undefined,
    stream: undefined,
    requestingMic: false,
    tagOpen: false
  }

  componentWillUnmount() {
    this.closeStream()
  }

  closeStream = () => {
    const { stream, recorder } = this.state

    if (!stream || !recorder) {
      return
    }

    recorder.stop()
    stream.getTracks().forEach(track => track.stop())
  }

  startRecording = () => {
    this.setState({
      requestingMic: true
    })

    navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
      const recorder = new MediaRecorder(stream)

      recorder.addEventListener('dataavailable', e => {
        this.setState({
          blobURL: URL.createObjectURL(e.data)
        })
      })

      this.setState({ recorder, stream, requestingMic: false }, () => {
        recorder.start()
      })
    })
  }

  stopRecording = () => {
    this.state.recorder.stop()
    this.closeStream()

    this.setState({
      recorder: undefined,
      stream: undefined,
      doneRecording: true
    })
  }

  render() {
    const {
      viewer,
      modals,
      loading,
      onSubmit,
      community,
      close,
      placeholder,
      onImages,
      images,
      inputId
    } = this.props
    const {
      text,
      blobURL,
      doneRecording,
      recorder,
      requestingMic,
      tagOpen
    } = this.state

    return (
      <Form
        className="mt1"
        loading={loading}
        onSubmit={() => {
          if (!viewer) {
            return modals.signUp()
          }
          onSubmit({ text, blobURL })
        }}
      >
        {!doneRecording && !recorder && (
          <FormTextArea
            id="commentInputTextArea"
            autoFocus
            placeholder={placeholder}
            style={{ fontFamily: 'Lato' }}
            value={text}
            onChange={e => {
              const newText = e.target.value
              this.setState({
                text: newText,
                tagOpen:
                  newText.length > text.length &&
                  newText[newText.length - 1] === '@'
              })
            }}
          />
        )}
        {recorder && <div className="my2">Recording...</div>}
        {blobURL && (
          <div>
            <audio src={blobURL} controls />
          </div>
        )}
        <ImagesEditor
          inputId={inputId}
          hideButton
          images={images}
          onImages={onImages}
        />
        <Button
          disabled={recorder}
          color={community.color}
          compact
          content="Submit"
          size="tiny"
        />
        {viewer && viewer.level >= 2 && (
          <Button
            type="button"
            disabled={doneRecording || requestingMic}
            compact
            icon={requestingMic ? undefined : recorder ? 'stop' : 'microphone'}
            content={
              requestingMic ? 'Mic Blocked' : recorder ? 'Stop' : 'Record'
            }
            size="tiny"
            onClick={recorder ? this.stopRecording : this.startRecording}
          />
        )}
        <TagUserInput
          open={tagOpen}
          onClose={() => {
            this.setState({ tagOpen: false })
            const el = document.getElementById('commentInputTextArea')
            if (el) {
              el.focus()
            }
          }}
          isOpen={tagOpen}
          onOpen={() => this.setState({ tagOpen: true })}
          onSelect={link => {
            const el = document.getElementById('commentInputTextArea')
            if (el) {
              const startText = text.substring(0, el.selectionStart || 0)
              const endText = text.substring(el.selectionEnd || 0)
              this.setState({
                text: startText + link + endText
              })
            } else {
              this.setState({
                text: text + link
              })
            }
          }}
        />
        <UploadButton inputId={inputId} />
        <Button
          type="button"
          compact
          content="Cancel"
          onClick={close}
          size="tiny"
        />
        <FormattingHelp />
      </Form>
    )
  }
}

export default flow(withModals, withViewer)(CommentInput)
