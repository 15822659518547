import React from 'react'
import { Form } from 'semantic-ui-react'
import { toast } from 'react-toastify'
import api from '../utils/api'
import { withRouter } from 'react-router-dom'
import { withModals, withOnUser, withUser } from '../contexts'
import { flow } from 'lodash'
import errorToast from '../utils/errorToast'
import PageTitle from './PageTitle'
import PrimaryButton from './PrimaryButton'

class ResetPassword extends React.Component {
  state = {
    password: '',
    confirm: '',
    loading: false
  }

  save = async () => {
    const { password, confirm } = this.state
    if (password !== confirm) {
      return toast.error('Passwords do not match!')
    }
    if (password.length < 6) {
      return toast.error('Password must be at least 6 characters!')
    }

    this.setState({ loading: true })
    try {
      const id = this.props.match.params.id
      const { data } = await api.put('/auth/local/reset', { id, password })
      this.props.onUser(data)
      this.props.history.replace('/')
    } catch (e) {
      errorToast(e)
    } finally {
      this.setState({ loading: false })
    }
  }

  render() {
    return (
      <Form loading={this.state.loading}>
        <PageTitle title="Reset Password" />
        <Form.Input
          type="password"
          label="New Password"
          placeholder="New Password"
          onChange={e => this.setState({ password: e.target.value })}
        />
        <Form.Input
          type="password"
          label="Confirm Password"
          placeholder="Confirm Password"
          onChange={e => this.setState({ confirm: e.target.value })}
        />
        <PrimaryButton fluid content="Save" onClick={this.save} />
      </Form>
    )
  }
}

export default flow(withRouter, withUser, withModals, withOnUser)(ResetPassword)
