import React from 'react'
import PageTitle from './PageTitle'
import ErrorMessage from './errors/ErrorMessage'
import MyLoader from './MyLoader'
import { Button, Divider, Segment } from 'semantic-ui-react'
import withLoading from './hoc/withLoading'
import { withViewer } from '../contexts'
import FromNow from './FromNow'
import { Link } from 'react-router-dom'
import plural from '../utils/plural'
import ProfileAvatar from './ProfileAvatar'
import GoBackHome from './GoBackHome'

class Thread extends React.Component {
  render() {
    const { thread, viewer } = this.props
    const users = [thread.user1, thread.user2]
    const otherUser = users.find(user => user.id !== viewer.id) || thread.user2

    return (
      <Segment>
        <Link to={`/messages/${thread.id}`} className="black">
          <h4 className="m0">{thread.title}</h4>
          <span>
            <FromNow date={thread.updatedAt} />{' '}
            <span className="text-small text-dim">
              ({plural(thread.messageCount, 'Message', 'Messages')})
            </span>
          </span>
          <Divider />
          <ProfileAvatar user={otherUser} />
        </Link>
      </Segment>
    )
  }
}

const ThreadV = withViewer(Thread)

class PageContent extends React.Component {
  render() {
    const { data } = this.props

    return (
      <div>
        <Link to="/users/find">
          <Button content="Find a User" />
        </Link>
        {!data.rows || data.rows.length === 0
          ? null
          : data.rows.map(thread => (
              <ThreadV key={thread.id} thread={thread} />
            ))}
      </div>
    )
  }
}

class Threads extends React.Component {
  render() {
    const { data, loading, e } = this.props
    return (
      <div>
        <PageTitle title="Messages" />
        <GoBackHome />
        <div className="mt1" />
        <ErrorMessage error={e} />
        {loading && <MyLoader />}
        {data && <PageContent data={data} />}
      </div>
    )
  }
}

export default withLoading(Threads, {
  paginated: true
})
