import React, { useContext, useState } from 'react'
import { Button, Input, Modal } from 'semantic-ui-react'
import { RemoteConfigContext } from '../contexts'
import algoliaFromRemoteConfig from '../utils/algoliaFromRemoteConfig'
import errorToast from '../utils/errorToast'
import userFullName from '../utils/userFullName'
import ProfileAvatar from './ProfileAvatar'

export default ({ onSelect, isOpen, onOpen, onClose }) => {
  const { remoteConfig } = useContext(RemoteConfigContext)
  const algolia = algoliaFromRemoteConfig(remoteConfig)
  const [users, setUsers] = useState([])
  const [text, setText] = useState('')

  const internalDismiss = () => {
    onClose()
    setText('')
    setUsers([])
  }

  return (
    <Modal
      onClose={internalDismiss}
      open={isOpen}
      onOpen={onOpen}
      trigger={
        <Button
          type="button"
          size="tiny"
          content="@"
          compact
          onClick={onOpen}
        />
      }
    >
      <Modal.Content>
        <Input
          fluid
          placeholder="Search for a user to tag..."
          autoFocus
          value={text}
          onChange={async e => {
            setText(e.target.value)

            try {
              const { hits } = await algolia.users.search(text, {
                hitsPerPage: 100
              })
              setUsers(
                hits.map(hit => {
                  return {
                    id: hit.objectID,
                    ...hit,
                    objectID: undefined
                  }
                })
              )
            } catch (e) {
              errorToast(e)
            }
          }}
        />
        <div className="mt2" style={{ height: '60vh', overflowY: 'scroll' }}>
          {users.map(user => {
            return (
              <div
                onClick={() => {
                  const fullName = userFullName(user)
                  const link = `[${fullName}](${remoteConfig.baseURL}/users/${user.id})`
                  onSelect(link)
                  internalDismiss()
                }}
                style={{ cursor: 'pointer' }}
                key={user.id}
              >
                <ProfileAvatar user={user} />
              </div>
            )
          })}
          {users.length === 0 && (
            <div
              style={{
                display: 'flex',
                height: '100%',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              No results
            </div>
          )}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" content="Close" onClick={internalDismiss} />
      </Modal.Actions>
    </Modal>
  )
}
