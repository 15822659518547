import 'core-js/es/map'
import React from 'react'
import 'semantic-ui-css/semantic.min.css'
import 'react-image-gallery/styles/css/image-gallery.css'
import './index.css'
import './basscss.min.css'
import 'react-toastify/dist/ReactToastify.min.css'
import 'animate.css/animate.min.css'
import 'react-placeholder/lib/reactPlaceholder.css'
import App from './components/App'
import ReactDOM from 'react-dom'

window.MediaRecorder = require('audio-recorder-polyfill')

const fn = function(data) {
  data = data.data
  if (!data) {
    return
  }

  const { type, key, value } = data

  if (type === 'localStorage') {
    console.log('adding to localStorage', key, value)
    localStorage.setItem(key, value)
  }
}

window.addEventListener('message', fn)
document.addEventListener('message', fn)

ReactDOM.render(<App />, document.getElementById('root'))
