import { forOwn, isEqual } from 'lodash'
import moment from 'moment'
import React from 'react'
import MediaQuery from 'react-responsive'
import { ToastContainer } from 'react-toastify'
import { Button, Divider, Segment } from 'semantic-ui-react'
import io from 'socket.io-client'
import api, { setAuthorization } from '../utils/api'
import baseURL from '../utils/baseURL'
import { clearSensitiveCache } from '../utils/cache'
import devToast from '../utils/devToast'
import * as firebase from '../utils/firebase'
import { isAppleWebView } from '../utils/mobileUserAgent'
import setRootStyle from '../utils/setRootStyle'
import AppWrappers from './AppWrappers'
import BottomBannerAd from './BottomBannerAd'
import ContentSwitch from './ContentSwitch'
import LogInModal from './modals/LogInModal'
import SignUpModal from './modals/SignUpModal'
import Toolbar from './Toolbar'
import TOSModal from './TOSModal'

window.getUserBroadcast = new window.brcast()

function getUserFromLS() {
  const jwt = localStorage.getItem('jwt')
  const json = localStorage.getItem('user')
  if (!json || !jwt) {
    return
  }

  return JSON.parse(json)
}

class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      signUpModalOpen: false,
      logInModalOpen: false,
      user: getUserFromLS(),
      remoteConfig: null,
      loading: true,
      acceptedTerms: !!localStorage.getItem('acceptedTerms'),
      termsModalOpen: false
    }
  }

  onUser = user => {
    if (this.socket) {
      this.socket.disconnect()
    }

    if (user) {
      console.log(user)
      // devToast('got user', this.state.remoteConfig)
      const socket = io(`${baseURL}?type=users&id=${user.id}`)
      this.socket = socket
      socket.on('user', user => {
        console.log('[SOCKET]', 'users', user)
        this.setState({ user })
      })
      firebase.sendDeviceToken()
      localStorage.setItem('user', JSON.stringify(user))
      if (user.jwt) {
        localStorage.setItem('jwt', user.jwt)
        setAuthorization(user.jwt)
      }
    } else {
      devToast('onUser(null)', this.state.remoteConfig)
      clearSensitiveCache()
      localStorage.removeItem('user')
      localStorage.removeItem('jwt')
      setAuthorization(undefined)
    }
    this.setState({
      user
    })
  }

  componentDidMount() {
    this.getUser()
    this.getRemoteConfig()

    window.onstorage = () => {
      console.log('onstorage')
      const jwt = localStorage.getItem('jwt')
      if (jwt !== api.defaults.headers.authorization) {
        setAuthorization(jwt)
        this.getUser()
      }
    }

    setTimeout(this.getUser, 3000)

    window.getUserBroadcast.subscribe(jwt => {
      devToast(`onJwt: ${jwt || 'missing'}`, this.state.remoteConfig)
      localStorage.setItem('jwt', jwt)
      setAuthorization(jwt)
      this.getUser()
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(this.state.remoteConfig, prevState.remoteConfig)) {
      this.setCssVars()
    }
  }

  getUser = async () => {
    if (!api.defaults.headers.authorization) {
      const jwt = localStorage.getItem('jwt')
      if (jwt) {
        setAuthorization(jwt)
      } else return
    }

    try {
      const response = await api.get('/profile')
      this.onUser(response.data)
    } catch (e) {
      if (e.response && e.response.status === 401) {
        this.onUser(null)
      }
      console.error(e)
    }
  }

  onRemoteConfig = remoteConfig => {
    this.setState({ remoteConfig, loading: false })
  }

  /**
   * pull all colors from remote config and put them in the <html> tag style
   */
  setCssVars = () => {
    const { remoteConfig } = this.state
    const obj = {}

    forOwn(remoteConfig, (value, key) => {
      if (key.includes('color')) {
        obj['--' + key] = value

        const colorID = `color-${key}`
        const colorCurrent = document.getElementById(colorID)
        if (colorCurrent) {
          colorCurrent.parentNode.removeChild(colorCurrent)
        }

        const colorStyle = document.createElement('style')
        colorStyle.id = colorID
        colorStyle.type = 'text/css'
        colorStyle.innerHTML = `.${colorID} { color: ${value} !important; }`
        document.getElementsByTagName('head')[0].appendChild(colorStyle)

        const bgID = `bg-${key}`
        const bgCurrent = document.getElementById(bgID)
        if (bgCurrent) {
          bgCurrent.parentNode.removeChild(bgCurrent)
        }

        const bgStyle = document.createElement('style')
        bgStyle.id = bgID
        bgStyle.type = 'text/css'
        bgStyle.innerHTML = `.${bgID} { background-color: ${value} !important; border-color: ${value} !important; }`
        document.getElementsByTagName('head')[0].appendChild(bgStyle)
      }
    })

    setRootStyle(obj)
  }

  getRemoteConfig = async () => {
    try {
      const { data } = await api.get('/config')
      this.onRemoteConfig(data)
    } catch (e) {
      console.error(e)
      this.setState({ loading: false, remoteConfig: null })
    }
  }

  toggleSignUpModal = () =>
    this.setState({ signUpModalOpen: !this.state.signUpModalOpen })
  toggleLogInModal = () =>
    this.setState({ logInModalOpen: !this.state.logInModalOpen })

  onCloseBottomBanner = () => {
    localStorage.setItem('bottomBannerClosedAt2', new Date().toISOString())
    this.setState({ x: Math.random() })
  }

  render() {
    const {
      signUpModalOpen,
      logInModalOpen,
      user,
      loading,
      remoteConfig,
      acceptedTerms,
      termsModalOpen
    } = this.state

    if (!remoteConfig) {
      if (loading) {
        return null
      } else {
        return (
          <Segment style={{ height: 'calc(100vh)' }} basic textAlign="center">
            <h4>Unable to Connect</h4>
            <p>
              Our server could be down, or you could have no network connection.
            </p>
            <Divider hidden />
            <Button
              basic
              size="small"
              content="Try again"
              onClick={() => {
                this.getUser()
                this.getRemoteConfig()
              }}
            />
          </Segment>
        )
      }
    }

    const wrappers = {
      user,
      onUser: this.onUser,
      modals: {
        signUp: this.toggleSignUpModal,
        logIn: this.toggleLogInModal
      },
      remoteConfig,
      onRemoteConfig: this.onRemoteConfig
    }

    const bottomBannerClosedAt = localStorage.getItem('bottomBannerClosedAt2')
    const hideBottomBanner =
      isAppleWebView() ||
      (bottomBannerClosedAt &&
        ((user && user.premium) ||
          moment(bottomBannerClosedAt)
            .add(5, 'days')
            .isAfter()))

    const showBottomBannerAd = !hideBottomBanner

    const acceptTerms = () => {
      localStorage.setItem('acceptedTerms', 'true')
      this.setState({ termsModalOpen: false, acceptedTerms: true })
    }

    return (
      <AppWrappers {...wrappers}>
        <Toolbar />
        <div className="flex flex-column main-content">
          {!acceptedTerms && (
            <div className="tos-bar flex items-center">
              <div style={{ flex: 1, marginRight: 2 }}>
                By continuing to use this application, you agree to our{' '}
                <span
                  className="anchor"
                  onClick={() => this.setState({ termsModalOpen: true })}
                >
                  EULA
                </span>
                .
              </div>
              <span onClick={acceptTerms} className="anchor">
                I Agree
              </span>
            </div>
          )}
          <MediaQuery minWidth={768}>
            <div
              className="flex-auto flex-grow"
              style={showBottomBannerAd ? { marginBottom: 108 } : {}}
            >
              <ContentSwitch />
            </div>
          </MediaQuery>
          <MediaQuery maxWidth={767}>
            <div
              className="flex-auto flex-grow"
              style={showBottomBannerAd ? { marginBottom: 54 } : {}}
            >
              <ContentSwitch />
            </div>
          </MediaQuery>
        </div>
        {showBottomBannerAd && (
          <BottomBannerAd onClose={this.onCloseBottomBanner} />
        )}
        {signUpModalOpen && <SignUpModal />}
        {logInModalOpen && <LogInModal />}
        {termsModalOpen && (
          <TOSModal
            close={() => this.setState({ termsModalOpen: false })}
            onAccept={acceptTerms}
          />
        )}
        <ToastContainer toastClassName="lato" position="bottom-right" />
      </AppWrappers>
    )
  }
}

export default App
