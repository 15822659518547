import React from 'react'
import Icon from '@mdi/react'
import { Popup } from 'semantic-ui-react'

export default ({ label, path, colorClass, description }) => {
  return (
    <Popup
      content={description}
      trigger={
        <div
          onClick={e => e.preventDefault()}
          className={`profile-badge-container ${colorClass}`}
        >
          <Icon path={path} size={0.8} color="white" />
          <span className="bold h6 white ml1">{label}</span>
        </div>
      }
    />
  )
}
