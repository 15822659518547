import axios from 'axios/index'
import apiURL from './apiURL'

const api = axios.create({
  baseURL: apiURL,
  timeout: 30000,
  headers: {
    authorization: localStorage.getItem('jwt') || undefined
  }
})

export function setAuthorization(jwt) {
  api.defaults.headers = {
    authorization: jwt || undefined
  }
}

setTimeout(() => {
  setAuthorization(localStorage.getItem('jwt'))
}, 2000)

export default api
