import { flow } from 'lodash'
import React, { Fragment } from 'react'
import MediaQuery from 'react-responsive'
import { Link } from 'react-router-dom'
import { Button, Divider, Icon, Segment } from 'semantic-ui-react'
import { withRemoteConfig, withViewer } from '../contexts'
import { isAppleWebView } from '../utils/mobileUserAgent'
import CommunityHeader from './CommunityHeader'
import CreateCommunity from './CreateCommunity'
import ErrorMessage from './errors/ErrorMessage'
import withLoading from './hoc/withLoading'
import MarkdownParagraph from './MarkdownParagraph'
import MyLoader from './MyLoader'
import NewTab from './NewTab'
import PageTitle from './PageTitle'

class Communities extends React.PureComponent {
  state = {
    showCreate: false
  }

  toggleCreate = () => this.setState({ showCreate: !this.state.showCreate })

  render() {
    const { viewer, loading, e, load, data, premium } = this.props
    const { showCreate } = this.state

    return (
      <div>
        <PageTitle title="Forums" hidden />
        {viewer && viewer.level >= 2 && (
          <Button
            content="Create Community"
            disabled={showCreate}
            onClick={this.toggleCreate}
          />
        )}
        {showCreate && (
          <Fragment>
            <CreateCommunity load={load} close={this.toggleCreate} />
            <Divider />
          </Fragment>
        )}
        <ErrorMessage error={e} />
        {loading && <MyLoader />}
        {!isAppleWebView() && (
          <NewTab
            href={'https://www.playingfire.com/buy-product?utm_source=forums'}
          >
            <MediaQuery minWidth={768}>
              <img
                style={{
                  marginTop: 8,
                  width: '100%',
                  objectFit: 'contain',
                  borderRadius: '.28571429rem',
                  maxHeight: 200
                }}
                src={
                  premium
                    ? 'https://u.cubeupload.com/Moderator/upgradedesktop.png'
                    : 'https://u.cubeupload.com/Moderator/NewBannerDesktop.png'
                }
                alt="Banner"
              />
            </MediaQuery>
            <MediaQuery maxWidth={767}>
              <img
                style={{
                  marginTop: 8,
                  width: '100%',
                  objectFit: 'contain',
                  borderRadius: '.28571429rem',
                  maxHeight: 200
                }}
                src={
                  premium
                    ? 'https://u.cubeupload.com/Moderator/upgrademobile.png'
                    : 'https://u.cubeupload.com/Moderator/NewBannerMobile.png'
                }
                alt="Banner"
              />
            </MediaQuery>
          </NewTab>
        )}
        {data &&
          data.rows &&
          data.rows
            .filter(community => {
              return !(isAppleWebView() && community.isPrivate && !premium)
            })
            .map(community => (
              <Segment
                raised={community.isPrivate && !premium}
                key={community.id}
                color={
                  !community.isPrivate || premium ? community.color : 'yellow'
                }
              >
                <Link to={`/communities/${community.id}`}>
                  <CommunityHeader community={community} />
                  <Divider />
                  <div style={{ position: 'relative' }}>
                    <div
                      style={{
                        filter:
                          community.isPrivate && !premium
                            ? 'blur(10px)'
                            : undefined,
                        overflow: 'hidden'
                      }}
                      className="black"
                    >
                      <MarkdownParagraph
                        startExpanded
                        text={
                          community.isPrivate && !premium
                            ? fakeDescription
                            : community.description
                        }
                      />
                    </div>
                    {community.isPrivate && !premium && (
                      <div
                        className="black flex flex-column justify-center items-center my1"
                        style={{
                          width: '100%',
                          position: 'absolute',
                          top: 0,
                          bottom: 0
                        }}
                      >
                        <Icon size="big" color="grey" name="lock" />
                        <h3 className="m0 mt1">Premium Content</h3>
                        <p className="center">
                          In order to view this community, you need to be a
                          mastermind member. Click here to find out more!
                        </p>
                      </div>
                    )}
                  </div>
                </Link>
              </Segment>
            ))}
      </div>
    )
  }
}

export default withLoading(flow(withRemoteConfig, withViewer)(Communities), {
  endpoint: '/communities',
  paginated: true,
  hidePaginationOnSinglePage: true
})

const fakeDescription =
  'Hey there!\n\nLooks like you know how to inspect HTML!\n\nCongrats on being a good programmer. I need this line to be really long.\n\nfilter(blur) is fun\n\nWould you not agree, Mr. Amazing Programmer?'
