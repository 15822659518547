import React from 'react'
import { Button, Form, Modal, Segment } from 'semantic-ui-react'
import validateEmail from '../../utils/validateEmail'
import api from '../../utils/api'
import { withModals, withOnUser, withRemoteConfig } from '../../contexts'
import { flow } from 'lodash'
import { toast } from 'react-toastify'
import errorString from '../../utils/errorString'
import errorToast from '../../utils/errorToast'
import PrimaryButton from '../PrimaryButton'
import SocialLoginButtons from '../SocialLoginButtons'

class LogInModal extends React.Component {
  state = {
    loading: false,
    email: null,
    password: null
  }

  onSignUpClicked = () => {
    this.props.modals.signUp()
    this.props.modals.logIn()
  }

  onSubmit = async e => {
    e.preventDefault()
    const { email, password } = this.state
    const input = { email, password }

    this.setLoading(true)
    try {
      const response = await api.post('/auth/local/login', input)
      this.setLoading(false)
      this.props.modals.logIn()
      this.props.onUser(response.data)
    } catch (e) {
      let error
      if (e.response && e.response.status === 401) {
        error = 'Invalid credentials.'
      } else {
        error = errorString(e)
      }
      toast.error(error)
      this.setLoading(false)
    }
  }

  setLoading = loading => this.setState({ loading })

  forgot = async () => {
    const { email } = this.state
    if (!validateEmail(email)) {
      return toast.error('Please enter a valid email.')
    }

    this.setLoading(true)
    try {
      await api.post('/auth/local/reset', { email })
      toast.success('Check your email for a reset password link!')
    } catch (e) {
      errorToast(e)
    } finally {
      this.setLoading(false)
    }
  }

  onInput = ({ target: { name, value } }) => this.setState({ [name]: value })

  render() {
    const { loading } = this.state

    return (
      <Modal
        className="modal-fix"
        closeOnDimmerClick={!loading}
        open={true}
        onClose={this.props.modals.logIn}
        size="small">
        <Modal.Content>
          <SocialLoginButtons remoteConfig={this.props.remoteConfig} />
          <Form onSubmit={this.onSubmit} loading={loading}>
            <Form.Input
              required
              label="Email"
              onChange={this.onInput}
              name="email"
              type="email"
              placeholder="Email"
            />
            <Form.Input
              required
              label="Password"
              onChange={this.onInput}
              name="password"
              type="password"
              placeholder="Password"
            />
            <PrimaryButton fluid>Log In</PrimaryButton>
            <div className="mt1">
              <span className="bold pointer py1 pr1" onClick={this.forgot}>
                Forgot your password?
              </span>
            </div>
            <Segment textAlign="center" basic>
              <p>Don't have an account?</p>
              <Button size="tiny" basic onClick={this.onSignUpClicked}>
                Sign Up
              </Button>
            </Segment>
          </Form>
        </Modal.Content>
      </Modal>
    )
  }
}

export default flow(
  withModals,
  withOnUser,
  withRemoteConfig
)(LogInModal)
