import React from 'react'
import Content from '../Content'
import { withRouter } from 'react-router-dom'

const content = path => (
  <div>
    <h1>Whoops!</h1>
    <p>That's a 404, captain. Maybe Zach is still working on it?</p>
    <p className="text-small text-dim">{path}</p>
  </div>
)

const NotFound = ({ wrap, history }) =>
  !wrap ? (
    content(history.location.pathname)
  ) : (
    <Content>{content(history.location.pathname)}</Content>
  )

export default withRouter(NotFound)
