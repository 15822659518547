import React from 'react'
import { Button } from 'semantic-ui-react'

const ColoredButton = ({ color, className, ...otherProps }) => {
  const backgroundClass = color
    ? `bg-color${color[0].toUpperCase()}${color.substring(1)}`
    : ''
  const textColor = color ? 'color-colorWhite' : 'colorBlack'
  return (
    <Button
      className={`${textColor} hover-lighter ${className ||
        ''} ${backgroundClass}`}
      {...otherProps}
    />
  )
}

export default ColoredButton
