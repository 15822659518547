import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment/moment'
import { Popup } from 'semantic-ui-react'

class FromNow extends React.Component {
  componentDidMount() {
    this.interval = setInterval(
      () => !this.props.edited && this.setState({}),
      10000
    )
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    const { date, edited, requested } = this.props

    if (!date) {
      return null
    }

    let content = moment(date).format('LLL')
    if (edited) {
      content += ` edited ${moment(edited).format('LLL')}`
    }

    return (
      <Popup
        size={'small'}
        trigger={
          <span className="text-dim text-small">
            {requested ? 'Requested ' : ''}
            {moment(date).fromNow()}
            {edited ? '*' : ''}
          </span>
        }
        content={content}
        inverted
        hideOnScroll
      />
    )
  }
}

FromNow.propTypes = {
  date: PropTypes.string,
  edited: PropTypes.any,
  requested: PropTypes.bool
}

export default FromNow
