import { flow } from 'lodash'
import React, { Fragment } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Button, Divider, Form, FormTextArea, Message } from 'semantic-ui-react'
import { withModals, withViewer } from '../contexts'
import api from '../utils/api'
import errorToast from '../utils/errorToast'
import { getAndSendToken } from '../utils/firebase'
import isModerator from '../utils/isModerator'
import CommunityHeader from './CommunityHeader'
import Editable from './Editable'
import EmptyState from './EmptyState'
import ErrorMessage from './errors/ErrorMessage'
import FormattingHelp from './FormattingHelp'
import withLoading from './hoc/withLoading'
import MarkdownParagraph from './MarkdownParagraph'
import MyLoader from './MyLoader'
import NewTab from './NewTab'
import PageTitle from './PageTitle'
import Post from './Post'
import PostForm from './PostForm'
import ColorSelector from './selectors/ColorSelector'
import SortTabs from './SortTabs'

class CommunityPage extends React.PureComponent {
  state = {
    subscribing: false,
    showPost: false,
    showEdit: false,
    descriptionInput: null,
    editing: false,
    deleting: false,
    showDeleteModal: false,
    deleteModalText: '',
    subscribeMessageDismissed: false
  }

  subscribe = async () => {
    getAndSendToken()

    const {
      viewer,
      modals: { signUp },
      data: { id, subscribed },
      load
    } = this.props
    if (!viewer) {
      return signUp()
    }

    this.setState({ subscribing: true })

    try {
      await api.post(`/communities/${id}/subscribe`, {
        subscribed: !subscribed
      })
      load()
    } catch (e) {
      errorToast(e)
    } finally {
      this.setState({ subscribing: false })
    }
  }

  edit = async () => {
    const { descriptionInput: description, color } = this.state

    this.setState({ editing: true })

    const update = {}
    if (description !== null) {
      update.description = description
    }
    if (color) {
      update.color = color
    }
    if (Object.keys(update).length === 0) {
      return errorToast("You didn't change anything!")
    }

    try {
      await api.put(`/communities/${this.props.match.params.id}`, update)

      this.props.load()
      this.setState({
        editing: false,
        showEdit: false
      })
    } catch (e) {
      errorToast(e)
      this.setState({
        editing: false
      })
    }
  }

  componentDidUpdate() {
    if (this.props.data) {
      if (this.props.match.params.id !== this.props.data.id) {
        this.props.history.replace(`/communities/${this.props.data.id}`)
      }
    }
  }

  togglePost = () => this.setState({ showPost: !this.state.showPost })
  toggleEditingDescription = () =>
    this.setState({
      showEdit: !this.state.showEdit,
      descriptionInput: null
    })

  render() {
    const {
      subscribing,
      showPost,
      showEdit,
      descriptionInput,
      editing,
      subscribeMessageDismissed
    } = this.state
    const { loading, data: community, e, load, viewer } = this.props

    const subscribeMessageDismissedKey = `subscribeMessageDismissed-${community &&
      community.id}`
    const showSubscribeMessage =
      !subscribeMessageDismissed &&
      community &&
      !community.subscribed &&
      !localStorage.getItem(subscribeMessageDismissedKey)

    return (
      <div>
        <ErrorMessage error={e} />
        {loading && <MyLoader />}
        {community && (
          <Fragment>
            <PageTitle
              hidden
              title={community.displayName || community.id}
              description={community.description}
            />
            <CommunityHeader
              inverted={community.isPrivate}
              community={community}
              large
              allowEdits
              load={load}
            />
            <Editable
              editable={isModerator(viewer, community)}
              editingView={
                <Form onSubmit={e => e.preventDefault()} loading={editing}>
                  <FormTextArea
                    value={
                      descriptionInput === null
                        ? community.description
                        : descriptionInput
                    }
                    onChange={e =>
                      this.setState({ descriptionInput: e.target.value })
                    }
                  />
                  <ColorSelector
                    color={this.state.color || community.color}
                    onSelect={color => this.setState({ color })}
                  />
                  <FormattingHelp />
                </Form>
              }
              onSave={this.edit}
              normalView={
                <MarkdownParagraph startExpanded text={community.description} />
              }
              editing={showEdit}
              toggle={this.toggleEditingDescription}
            />
            <Divider />
            <div className="flex flex-wrap items-baseline mnt">
              <Button
                compact
                color={community.color}
                disabled={showPost}
                content="Post"
                onClick={this.togglePost}
              />
              <Button
                compact
                color={community.color}
                content={community.subscribed ? 'Unsubscribe' : 'Subscribe'}
                loading={subscribing}
                onClick={this.subscribe}
              />
              <div className="mt1 flex">
                <Link to={`/communities/${community.id}/search`}>
                  <Button
                    compact
                    basic
                    color={community.color}
                    content="Search"
                    icon="search"
                  />
                </Link>
                {community.ctaTitle && community.ctaUrl && (
                  <NewTab href={community.ctaUrl}>
                    <Button
                      basic
                      compact
                      color={community.color}
                      content={community.ctaTitle}
                    />
                  </NewTab>
                )}
              </div>
            </div>
            {showSubscribeMessage && (
              <Message
                className="pointer"
                warning
                onDismiss={() => {
                  localStorage.setItem(subscribeMessageDismissedKey, '1')
                  this.setState({ subscribeMessageDismissed: true })
                }}
                content={
                  <span>
                    Don't forget to subscribe to this community. It's free!{' '}
                    <span className="anchor" onClick={this.subscribe}>
                      Click here to get alerts for new posts!
                    </span>
                  </span>
                }
              />
            )}
            {showPost && (
              <Fragment>
                <Divider />
                <PostForm
                  community={community}
                  close={this.togglePost}
                  onComplete={() => {
                    this.props.load()
                    this.togglePost()
                  }}
                />
              </Fragment>
            )}
            {community.parentPosts.length > 0 && (
              <Fragment>
                {community.parentPosts.map(post => (
                  <Post
                    greenSegment
                    hideImages
                    key={post.id}
                    post={post}
                    community={community}
                    load={load}
                    hideText
                    noEditTitle
                    hideVoteControls
                    hideLabels
                    hideActions
                  />
                ))}
              </Fragment>
            )}
            <SortTabs community={community}></SortTabs>
            {community.posts.length > 0 ? (
              community.posts.map(post => (
                <Fragment key={post.id}>
                  <Post
                    hideImages
                    post={post}
                    community={community}
                    load={load}
                  />
                  <Divider />
                </Fragment>
              ))
            ) : (
              <EmptyState />
            )}
          </Fragment>
        )}
      </div>
    )
  }
}

export default withLoading(
  flow(withViewer, withModals, withRouter)(CommunityPage),
  {
    paginated: true
  }
)
