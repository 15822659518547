import React from 'react'
import withLoading from './hoc/withLoading'
import ErrorMessage from './errors/ErrorMessage'
import Dimmer from './Dimmer'
import EmptyState from './EmptyState'
import Comment from './Comment'
import PageTitle from './PageTitle'
import ProfileAvatar from './ProfileAvatar'

const UserPostsPage = ({ data, loading, e, load }) => (
  <div>
    <ErrorMessage error={e} />
    {loading && <Dimmer />}
    {data && (
      <div>
        <PageTitle hidden title={`${data.user.firstName}'s comments`} />
        <ProfileAvatar user={data.user} large link />
        {data.rows && data.rows.length > 0 ? (
          data.rows.map(comment => (
            <Comment
              key={comment.id}
              comment={comment}
              post={comment.rootPost}
              load={load}
            />
          ))
        ) : (
          <EmptyState />
        )}
      </div>
    )}
  </div>
)

export default withLoading(UserPostsPage, {
  paginated: true
})
