import React, { Fragment } from 'react'
import MyLoader from './MyLoader'
import ErrorMessage from './errors/ErrorMessage'
import withLoading from './hoc/withLoading'
import Comment from './Comment'
import Post from './Post'
import PageTitle from './PageTitle'

class CommentPage extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.props.load()
    }
  }

  render() {
    const { loading, data, e, load } = this.props

    return (
      <div>
        <PageTitle hidden title="Comment" />
        {loading && <MyLoader />}
        <ErrorMessage error={e} />
        {data && (
          <Fragment>
            <Post
              post={data.rootPost}
              community={data.rootPost.community}
              load={load}
            />
            <h4>You are viewing a single comment thread:</h4>
            <Comment comment={data} post={data.rootPost} load={load} />
          </Fragment>
        )}
      </div>
    )
  }
}

export default withLoading(CommentPage, {
  paginated: false
})
